export const columnListImportDashboardIND = [
    { key: 'date', label: 'Date', width: 130 },
    { key: 'hs_code', label: 'HS Code', width: 150 },
    { key: 'prod_desc', label: 'Product Description', width: 400 },
    { key: 'importer', label: 'Importer Name', width: 200 },
    { key: 'foreign_exporter', label: 'Exporter', width: 200 },
    { key: 'for_count', label: 'Foreign Country', width: 200 },
    { key: 'for_port', label: 'Port of Loading', width: 200 },
	{ key: 'ind_port', label: 'Port of Destination', width: 200 },
    { key: 'std_qty', label: 'Std. Qty', width: 100 },
    { key: 'std_unit', label: 'Std. Unit', width: 100 },
    { key: 'value_usd', label: 'Total Value USD', width: 100 },
	{ key: 'std_unit_rate_usd', label: 'Std. Unit Price in USD', width: 100 },
    { key: 'qty', label: 'Quantity', width: 100 },
    { key: 'unit', label: 'Unit', width: 100 },
    { key: 'unit_rate_usd', label: 'Unit Price in USD', width: 100 },
    { key: 'ship_mode', label: 'Shipment Mode', width: 200 },
    { key: 'unitprice_fc', label: 'Unit Price_FC', width: 200 },
    { key: 'currency', label: 'Currency', width: 200 },
	{ key: 'incoterm', label: 'Incoterm', width: 200 },
	{ key: 'month', label: 'Month', width: 200 },
	{ key: 'hs2', label: 'HS2', width: 100 },
    { key: 'hs4', label: 'HS4', width: 100 },

    
];


export const columnListImportDownloadIND = [
    { key: 'id', label: 'Declaration No', width: 100 },
    { key: 'date', label: 'Date', width: 130 },
    { key: 'hs_code', label: 'HS Code', width: 150 },
    { key: 'prod_desc', label: 'Product Description', width: 400 },
	{ key: 'importer', label: 'Importer Name', width: 200 },
    { key: 'address1', label: 'Importer Address1', width: 200 },
    { key: 'address2', label: 'Importer Address2', width: 200 },
    { key: 'city', label: 'Importer City', width: 200 },
    { key: 'pin', label: 'pin', width: 200 },
    { key: 'foreign_exporter', label: 'Exporter', width: 200 },
    { key: 'foreign_address', label: 'Exporter Address', width: 200 },
	{ key: 'qty', label: 'Quantity', width: 200 },
    { key: 'unit', label: 'Unit', width: 130 },
    { key: 'std_qty', label: 'Std. Qty', width: 200 },
    { key: 'std_unit', label: 'Std. Unit', width: 200 },
	{ key: 'value_usd', label: 'Total Value in USD', width: 200 },
    { key: 'unit_rate_usd', label: 'Unit Price in USD', width: 200 },
	{ key: 'std_unit_rate_usd', label: 'Std. Unit Price in USD', width: 130 },
	{ key: 'unitprice_fc', label: 'Unit Price_FC', width: 200 },
    { key: 'currency', label: 'Currency', width: 200 },
    { key: 'actual_duty', label: 'Tariff', width: 200 },
    { key: 'ind_port', label: 'Port of Destination', width: 200 },
    { key: 'for_count', label: 'Foreign Country', width: 200 },
    { key: 'for_port', label: 'Port of Loading', width: 200 },
    { key: 'ship_mode', label: 'Shipment Mode', width: 200 },
    { key: 'item_no', label: 'Item No', width: 200 },
    { key: 'incoterm', label: 'Incoterm', width: 200 },
	{ key: 'typ', label: 'Type', width: 200 },
    { key: 'cha_name', label: 'CHA NAME', width: 200 },
	{ key: 'month', label: 'Month', width: 200 },
	{ key: 'hs2', label: 'HS2', width: 100 },
    { key: 'hs4', label: 'HS4', width: 100 },

    
];



export const columnListExportDashboardIND = [
    { key: 'sb_date', label: 'Date', width: 120 },
    { key: 'hs_code', label: 'HS Code', width: 100 },
    { key: 'product', label: 'Product Description', width: 300 },
	{ key: 'indian_exportar_name', label: 'Exporter', width: 200 },
    { key: 'foreign_importer_name', label: 'Importer', width: 200 },
	{ key: 'city_of_destination', label: 'Destination Country', width: 200 },
	{ key: 'port_of_destination', label: 'Port of Destination', width: 200 },
	{ key: 'port_of_origin', label: 'Port of Loading', width: 200 },
    { key: 'std_qty', label: 'Std. Qty', width: 100 },
    { key: 'std_unit', label: 'Std. Unit', width: 100 },
	{ key: 'value_usd', label: 'Total Value in USD', width: 100 },
	{ key: 'std_unit_rate_usd', label: 'Std. Unit Price in USD', width: 100 },
    { key: 'qty', label: 'Quantity', width: 100 },
    { key: 'unit', label: 'Unit', width: 100 },
    { key: 'unit_rate_in_foreign_currency', label: 'Unit Price in FC', width: 100 },
    { key: 'unit_rate_currency', label: 'Currency', width: 100 },
    { key: 'unit_rate_usd', label: 'Unit Price in USD', width: 100 },
    { key: 'value_in_fc', label: 'Total Value in FC', width: 100 },   
    { key: 'ship_mode', label: 'Shipment Mode', width: 200 },
	{ key: 'incoterm', label: 'Incoterm', width: 200 },
	{ key: 'month', label: 'Month', width: 100 },
    { key: 'hs2', label: 'HS2', width: 100 },
    { key: 'hs4', label: 'HS4', width: 100 },
    

];

export const columnListExportDownloadIND = [
    { key: 'id', label: 'Declaration No', width: 100 },
    { key: 'sb_date', label: 'Date', width: 130 },
    { key: 'hs_code', label: 'HS Code', width: 150 },
    { key: 'product', label: 'Product Description', width: 400 },
    { key: 'indian_exportar_name', label: 'Exporter', width: 200 },
    { key: 'exporter_add1', label: 'Exporter Address1', width: 200 },
    { key: 'exporter_add2', label: 'Exporter Address2', width: 200 },
    { key: 'exporter_city', label: 'Exporter City', width: 200 },
	{ key: 'pin', label: 'Exporter pin', width: 200 },
    { key: 'foreign_importer_name', label: 'Importer', width: 200 },
    { key: 'for_add1', label: 'Importer Address1', width: 200 },
    { key: 'for_add2', label: 'Importer Address2', width: 200 },
    { key: 'for_add3', label: 'Importer Address3', width: 200 },
    { key: 'for_add4', label: 'Importer Address4', width: 200 },
    { key: 'qty', label: 'Quantity', width: 200 },
    { key: 'unit', label: 'Unit', width: 130 },
    { key: 'std_qty', label: 'Std. Qty', width: 200 },
    { key: 'std_unit', label: 'Std. Unit', width: 200 },
	{ key: 'value_usd', label: 'Total Value in USD', width: 200 },
    { key: 'unit_rate_usd', label: 'Unit Price in USD', width: 200 },
	{ key: 'std_unit_rate_usd', label: 'Std. Unit Price in USD', width: 130 },
    { key: 'unit_rate_in_foreign_currency', label: 'Unit Price in FC', width: 200 },
    { key: 'unit_rate_currency', label: 'Currency', width: 200 },
	{ key: 'city_of_destination', label: 'Destination Country', width: 200 },
    { key: 'port_of_destination', label: 'Port of Destination', width: 200 },
    { key: 'port_of_origin', label: 'Port of Loading', width: 200 },
    { key: 'item_no', label: 'Item No', width: 200 },
    { key: 'draw_back', label: 'Drawback', width: 200 },
    { key: 'incoterm', label: 'Inco term', width: 200 },
    { key: 'ship_mode', label: 'Shipment Mode', width: 200 },
    { key: 'cha_name', label: 'CHA NAME', width: 200 },
    { key: 'month', label: 'Month', width: 100 },
    { key: 'hs2', label: 'HS2', width: 100 },
    { key: 'hs4', label: 'HS4', width: 100 },

];


export const columnListImportUSA = [
    // { key: 'id', label: 'Sl', width: 100 },
	{ key: 'be_date', label: 'Date', width: 200 },
    { key: 'product_description', label: 'Product Description', width: 200 },
    { key: 'importer_name', label: 'Importer Name', width: 200 },
    { key: 'exporter_name', label: 'Exporter Name', width: 200 },
    { key: 'notify_party_name', label: 'Notify Party Name', width: 200 },	
    { key: 'origin_country', label: 'Country of Origin', width: 200 },
    { key: 'origin_port', label: 'Origin Port', width: 200 },
	{ key: 'destination_port', label: 'Destination Port', width: 200 },
	{ key: 'net_weight', label: 'Net Weight', width: 100 },
	{ key: 'net_weight_unit', label: 'Net Weight Unit', width: 100 },
	{ key: 'place_of_receipt', label: 'Place of Receipt', width: 200 },
    { key: 'hs_code', label: 'HSCODE', width: 100 },
	//{ key: 'importer_address', label: 'Importer Address', width: 200 },
	//{ key: 'exporter_address', label: 'Exporter Address', width: 200 },
	//{ key: 'notify_party_address', label: 'Notify Party Address', width: 200 },
	{ key: 'quantity', label: 'Quantity', width: 100 },
    { key: 'unit', label: 'UNIT', width: 100 },
    { key: 'bl_number', label: 'BL Number', width: 200 },
    { key: 'container', label: 'Container', width: 200 },
    { key: 'menifest_no', label: 'Menifest No.', width: 200 },
    { key: 'mode_of_transport', label: 'Mode of Transport', width: 200 },
	{ key: 'ship_name', label: 'Ship Name', width: 100 },
    { key: 'transport_company', label: 'Transport Company', width: 200 },
    { key: 'total_value_cif_usd', label: 'Total Value CIF USD', width: 200 },
	{ key: 'month', label: 'Month', width: 200 },
    { key: 'year', label: 'Year', width: 100 },
    { key: 'hs_code2', label: 'HD Code2', width: 200 },
    { key: 'hs_code4', label: 'HS Code4', width: 200 },
    { key: 'assed_unit_value', label: 'Assed Unit Value', width: 200 },
    { key: 'assessed_import_value_pkr', label: 'Assessed Import Value PKR', width: 200 },
    { key: 'assessed_value', label: 'Assessed Value', width: 200 },
	{ key: 'assessed_value_currency', label: 'Assessed Value Currency', width: 200 },
    { key: 'attributes', label: 'Attributes', width: 200 },
    { key: 'be_no', label: 'BE VO.', width: 100 },
    { key: 'bl_date', label: 'BL Date', width: 200 },
    { key: 'brand', label: 'Brand', width: 200 },
    { key: 'cif_unit_price', label: 'CIF Unit Price', width: 200 },
    { key: 'cif_value_botswanan_pula', label: 'cif_value_botswanan_pula', width: 200 },
    { key: 'commercial_deposit', label: 'Commercial Deposit', width: 200 },
    { key: 'commercial_quantity', label: 'Commercial Quantity', width: 200 },
    { key: 'commercial_quantity_unit', label: 'Commercial Quantity Unit', width: 200 },
    { key: 'condition', label: 'Condition', width: 200 },
    { key: 'conocimiento_emb', label: 'Conocimiento Emb', width: 200 },
    { key: 'currency', label: 'Currency', width: 200 },
    { key: 'custom_clearing_agent', label: 'Custom Clearing Agent', width: 200 },
    //{ key: 'custom_clearing_agent_address', label: 'Custom Clearing Agent Address', width: 200 },
    { key: 'custom_clearing_agent_code', label: 'Custom Clearing Agent Code', width: 200 },
    { key: 'custom_clearing_office', label: 'Custom Clearing Office', width: 200 },
    { key: 'declarant', label: 'Declarant', width: 100 },
    { key: 'declarant_code', label: 'Declarant Code', width: 100 },
    { key: 'declaration_type', label: 'Declaration Type', width: 200 },
    { key: 'delivery_term_place', label: 'Delivery Term Place', width: 200 },
    { key: 'destination_country', label: 'Destination Country', width: 150 },
    { key: 'destination_port_code', label: 'Destination Port Code', width: 100 },
    { key: 'duty', label: 'Duty', width: 200 },  
    { key: 'duty_mod', label: 'Duty Mod', width: 200 },
    { key: 'economic_zone', label: 'Economic Zone', width: 200 },
    { key: 'exchange_rate', label: 'Exchange Rate', width: 200 },
    { key: 'export_country', label: 'Export Country', width: 200 },
    //{ key: 'exporter_phone_email', label: 'Exporter Phone Email', width: 200 },
    { key: 'fob_value_botswanan_pula', label: 'fob_value_botswanan_pula', width: 200 },
    { key: 'freight_value_botswanan_pula', label: 'Freight Value botswanan_pula', width: 200 },
    { key: 'freight_value_usd', label: 'Freight Value USD', width: 200 },
    { key: 'gross_weight', label: 'Gross Weight', width: 200 },
    { key: 'gross_weight_unit', label: 'Gross Weight Unit', width: 200 }, 
    { key: 'hs_code6', label: 'HS Code6', width: 200 },
    { key: 'hs_description', label: 'HS Description', width: 200 },
    { key: 'iec', label: 'IEC', width: 100 },
    { key: 'import_purpose', label: 'Import Purpose', width: 200 },
    { key: 'import_purpose_group', label: 'Import Purpose Group', width: 200 },
    { key: 'importer_economic_key', label: 'Importer Economic Key', width: 200 },
    //{ key: 'importer_email', label: 'Importer Email', width: 100 },
    //{ key: 'importer_phone', label: 'Importer Phone', width: 100 },
    { key: 'income_tax_amount', label: 'Income Tax Amount', width: 100 },
    { key: 'income_tax_mod', label: 'Income Tax Mod', width: 200 },
    { key: 'incoterms', label: 'Incoterms', width: 200 },
    { key: 'insurance_value_botswanan_pula', label: 'insurance_value_botswanan_pula', width: 200 },
    { key: 'insurance_value_usd', label: 'Insurance Value USD', width: 200 },
    { key: 'item_no', label: 'Item No', width: 200 },
    { key: 'item_value_cif', label: 'Item Value CIF', width: 200 },
    { key: 'item_value_fob', label: 'Item Value Fob', width: 200 },
    { key: 'luxury_tax_mod', label: 'Luxury Tax Mod', width: 200 },
    { key: 'manufacture_year', label: 'Manufacture Year', width: 200 },
    { key: 'menifest_date', label: 'Menifest Date', width: 200 },
    { key: 'mode_of_payment', label: 'Mode of Payment', width: 200 },
    { key: 'no_of_package', label: 'No Of Package', width: 200 },
    { key: 'notify_party_code', label: 'Notify Party Code', width: 100 },
    { key: 'origin_port_code', label: 'Port of Origin Code', width: 200 },  
    { key: 'package_description', label: 'Package Description', width: 200 },
    { key: 'package_type', label: 'Package Type', width: 200 },  
    { key: 'std_quantity', label: 'Std Quantity', width: 200 },
    { key: 'std_unit', label: 'Std Unit', width: 200 },
    { key: 'subitem_no', label: 'Subitem_no', width: 200 },
    { key: 'tax_amount', label: 'Tax Amount', width: 200 },
    { key: 'tax_percentge', label: 'Tax Percentge', width: 200 },
    { key: 'total_freight_value', label: 'Total Freight Value', width: 200 },
    { key: 'total_insurance_value', label: 'Total Insurance Value', width: 200 },
    { key: 'total_invoice_value', label: 'Total Invoice Value', width: 200 },
    { key: 'total_invoice_value_usd', label: 'Total Invoice Value USD', width: 200 },
    { key: 'total_value', label: 'Total Value', width: 100 },
    { key: 'total_value_bdt', label: 'Total Value_BDT', width: 100 },
    { key: 'total_value_cif', label: 'Total Value CIF', width: 200 },
    { key: 'total_value_cif_naira', label: 'Total Value CIF NAIRA', width: 200 },
    { key: 'total_value_cif_ugx', label: 'Total Value CIF UGX', width: 200 },
    { key: 'total_value_cif_zwd', label: 'Total Value CIF ZWD', width: 200 },
    { key: 'total_value_etb', label: 'Total Value ETB', width: 200 },
    { key: 'total_value_kes', label: 'Total Value KES', width: 200 },
    { key: 'total_value_lsl', label: 'Total Value LSL', width: 200 },
    { key: 'total_value_naira', label: 'Total Value NAIRA', width: 200 },
    { key: 'total_value_uah', label: 'Total Value UAH', width: 200 },
    { key: 'total_value_usd', label: 'Total Value USD', width: 200 },
    { key: 'transport_doc_date', label: 'Transport doc Date', width: 200 },
    { key: 'transport_doc_no', label: 'Transport doc No', width: 200 },
    { key: 'unit_price', label: 'Unit Price', width: 100 },
    { key: 'unit_price_bdt', label: 'Unit Price_BDT', width: 100 },
    { key: 'unit_price_kes', label: 'Unit Price_KES', width: 100 },
    { key: 'unit_price_usd', label: 'Unit Price USD', width: 200 }, 
    { key: 'value_added_tax_amount', label: 'Value Added Tax Amount', width: 200 },
    { key: 'value_added_tax_mod', label: 'Value Added Tax Mod', width: 200 },
    { key: 'variety', label: 'Variety', width: 200 },
    { key: 'vat_preference', label: 'VAT Preference', width: 200 },
    { key: 'importer', label: 'Indian Company Name', width: 200 },
    //{ key: 'address1', label: 'ADDRESS1', width: 200 },
    //{ key: 'address2', label: 'ADDRESS2', width: 200 },
    //{ key: 'city', label: 'CITY', width: 200 },
    //{ key: 'pin', label: 'PIN', width: 200 },
    { key: 'prod_desc', label: 'PROD_DESC', width: 200 },
    { key: 'ind_port', label: 'IND_PORT', width: 200 },
    { key: 'for_count', label: 'FOR_COUNT', width: 200 },
    { key: 'for_port', label: 'FOR_PORT', width: 200 },
    { key: 'value', label: 'VALUE', width: 50 },
    { key: 'value_usd', label: 'VALUE(US$)', width: 50 },
    { key: 'qty', label: 'QTY', width: 200 },   
    { key: 'val_duty_inr', label: 'VAL_DUTY_INR', width: 200 },
    { key: 'val_duty_usd', label: 'VAL_DUTY_USD', width: 200 },
    { key: 'applicable_duty', label: 'Applicable DUTY', width: 200 },
    { key: 'unit_rate_inr', label: 'UNIT RATE(IND RS)', width: 200 },
    { key: 'ship_mode', label: 'SHIP_MODE', width: 200 },
    { key: 'unit_rate_usd', label: 'UNIT RATE(USD)', width: 200 },
    { key: 'beno', label: 'BENO', width: 200 },
    { key: 'cha_no', label: 'CHA_NO', width: 200 },
    { key: 'cha_name', label: 'CHA_NAME', width: 200 },
    { key: 'actual_duty', label: 'Actual DUTY', width: 200 },
    { key: 'total_duty_on_entire_be', label: 'Total Duty On Entire BE', width: 200 },
    { key: 'typ', label: 'Typ', width: 200 },
    { key: 'ag', label: 'AG', width: 200 },
    { key: 'inv_no', label: 'Inv No', width: 200 },
    { key: 'unitprice_fc', label: 'UnitPrice FC', width: 200 },  
    { key: 'foreign_exporter', label: 'Foreign Exporter', width: 200 },
    //{ key: 'foreign_address', label: 'Foreign Address', width: 200 },
    { key: 'date', label: 'Date', width: 130 },
    { key: 'cush', label: 'CUSH', width: 200 },
    { key: 'location', label: 'Location', width: 200 },
    { key: 'inv_date', label: 'Inv_Date', width: 200 },
    { key: 'inv_srl_no', label: 'Inv_Srl_No', width: 200 },
    { key: 'inv_value', label: 'Inv_Value', width: 200 },
    { key: 'forcntry_code', label: 'ForCntry_Code', width: 200 },
    { key: 'forport_code', label: 'FORPORT_Code', width: 200 },
    { key: 'shipmentportcode', label: 'Shipment Port Code', width: 200 },
    { key: 'shipmentport', label: 'Shipment Port', width: 200 },
    { key: 'bcd_notn', label: 'BCD Notn', width: 200 },
    { key: 'bcd_rate', label: 'BCD Rate', width: 200 },
    { key: 'bcd_amt', label: 'BCD Amt', width: 200 },
    { key: 'cvd_notn', label: 'CVD Notn', width: 200 },
    { key: 'cvd_rate', label: 'CVD Rate', width: 200 },
    { key: 'cvd_amt', label: 'CVD Amt', width: 200 },
    { key: 'igst_amt', label: 'IGST Amt', width: 200 },
    { key: 'gst_cess_amt', label: 'GST Cess Amt', width: 200 },
    { key: 'BE_Date', label: 'BE_Date', width: 150 },
    { key: 'BE_NO', label: 'BE_NO', width: 100 },
    
    
];

export const columnListExportUSA = [
    // { key: 'id', label: 'Sl', width: 100 },
	{ key: 'date', label: 'Date', width: 150 },
    { key: 'product_description', label: 'Product Description', width: 200 },
	{ key: 'exporter_name', label: 'Exporter Name', width: 200 },
    { key: 'recepient_name', label: 'Recepient Name', width: 200 },
    { key: 'notify_party_name', label: 'Notify Party Name', width: 200 },
    { key: 'destination_country', label: 'Destination Country', width: 150 },
	{ key: 'destination_port', label: 'Destination Port', width: 150 },
    { key: 'origin_port', label: 'Origin Port', width: 200 },
    { key: 'ship_name', label: 'Ship Name', width: 100 },
    { key: 'net_weight', label: 'Net Weight', width: 100 },
    { key: 'net_weight_unit', label: 'Net Weight Unit', width: 100 },
    { key: 'place_of_receipt', label: 'Place of Receipt', width: 200 },
    { key: 'bl_number', label: 'BL Number', width: 200 },
	//{ key: 'exporter_address', label: 'Exporter Address', width: 200 },
	//{ key: 'recepient_address', label: 'Recepient Address', width: 200 },
	//{ key: 'notify_party_address', label: 'Notify Party Address', width: 200 },
	{ key: 'hs_code', label: 'HSCODE', width: 100 },
    { key: 'quantity', label: 'Quantity', width: 100 },
	{ key: 'unit', label: 'Unit', width: 80 },
	{ key: 'transport_company', label: 'Transport Company', width: 200 },
    { key: 'hs_description', label: 'HS Description', width: 200 },
    { key: 'quantity_of_container', label: 'Quantity of Container', width: 200 },
	{ key: 'voyage_number', label: 'Voyage Number', width: 200 },
    { key: 'type_of_cargo', label: 'Cargo Type', width: 100 },
    { key: 'year', label: 'Year', width: 100 },
    { key: 'month', label: 'Month', width: 200 },
	{ key: 'hs_code2', label: 'HD Code2', width: 150 },
    { key: 'hs_code4', label: 'HS Code4', width: 150 },
    { key: 'commercial_quantity_unit', label: 'Commercial Quantity Unit', width: 200 },
    { key: 'attributes', label: 'Attributes', width: 200 },
    { key: 'brand', label: 'Brand', width: 200 },
    { key: 'category', label: 'Category', width: 200 },
    { key: 'cif_value_botswanan_pula', label: 'cif_value_botswanan_pula', width: 200 },
    { key: 'cif_value_naira', label: 'cif_value_naira', width: 200 },
    { key: 'cif_value_ugx', label: 'cif_value_ugx', width: 200 },
    { key: 'clearance_fee', label: 'Clearance Fee', width: 200 },
    { key: 'clearing_agent', label: 'Clearing Agent', width: 200 },
    { key: 'commercial_quantity', label: 'Commercial Quantity', width: 200 },
    { key: 'condition', label: 'Condition', width: 200 },
    { key: 'conocimiento_emb', label: 'Conocimiento Emb', width: 200 },
    { key: 'container', label: 'Container', width: 200 },
    { key: 'currency', label: 'Currency', width: 200 },
    { key: 'customs_clearance_office', label: 'Customs Clearance Office', width: 200 },
    { key: 'declarant', label: 'Declarant', width: 100 },
    { key: 'declarant_code', label: 'Declarant Code', width: 100 },
    { key: 'declaration_type', label: 'Declaration Type', width: 200 },
    { key: 'destination_port_code', label: 'Destination Port Code', width: 100 },
    { key: 'economic_zone', label: 'Economic Zone', width: 200 },
    { key: 'exchange_rate', label: 'Exchange Rate', width: 200 },
    { key: 'export_country', label: 'Export Country', width: 200 },
    { key: 'export_purpose', label: 'Export Purpose', width: 200 },
    { key: 'export_purpose_group', label: 'Export Purpose Group', width: 200 },
    { key: 'exporter_economic_key', label: 'Exporter Economic Key', width: 200 },
    //{ key: 'exporter_tel', label: 'Exporter Phone', width: 200 },
    { key: 'fob_value_botswanan_pula', label: 'fob_value_botswanan_pula', width: 200 },
    { key: 'fob_value_naira', label: 'fob_value_naira', width: 200 },
    { key: 'freight_value', label: 'Freight Value', width: 200 },
    { key: 'freight_value_botswanan_pula', label: 'Freight Value botswanan_pula', width: 200 },
    { key: 'freight_value_usd', label: 'Freight Value USD', width: 200 },
    { key: 'gross_weight', label: 'Gross Weight', width: 200 },
    { key: 'gross_weight_unit', label: 'Gross Weight Unit', width: 200 },
    { key: 'hs_code6', label: 'HS Code6', width: 150 },
    { key: 'iec', label: 'IEC', width: 200 },
    { key: 'incoterm', label: 'Incoterm', width: 200 },
    { key: 'insurance_value', label: 'Insurance Value', width: 200 },
    { key: 'insurance_value_botswanan_pula', label: 'insurance_value_botswanan_pula', width: 200 },
    { key: 'insurance_value_usd', label: 'Insurance Value USD', width: 200 },
    { key: 'item_no', label: 'Item_no', width: 200 },
    { key: 'item_value_cif', label: 'Item Value CIF', width: 200 },
    { key: 'item_value_fob', label: 'Item Value Fob', width: 200 },
    { key: 'licence_code', label: 'Licence Code', width: 200 },
    { key: 'mode_of_transport', label: 'Mode of Transport', width: 200 },
    { key: 'no_of_package', label: 'No Of Package', width: 200 },
    { key: 'notify_party_code', label: 'Notify Party Code', width: 100 },
    { key: 'origin_country', label: 'Country of Origin', width: 200 },
    { key: 'origin_port_code', label: 'Port of Origin Code', width: 200 },
    { key: 'package_description', label: 'Package Description', width: 200 },
    { key: 'package_type', label: 'Package Type', width: 200 },
    { key: 'payment_mode', label: 'Place of Discharge', width: 200 },
    { key: 'purpose_consumption', label: 'Purpose of Consumption', width: 200 },
	{ key: 'foreign_importer_name', label: 'Foreign Importer Name', width: 200 },
    { key: 'sb_no', label: 'SB_NO', width: 100 },
    { key: 'std_quantity', label: 'Std Quantity', width: 200 },
    { key: 'std_unit', label: 'Std Unit', width: 200 },
    { key: 'subitem_no', label: 'Subitem_no', width: 200 },
    { key: 'tax_amount', label: 'Tax Amount', width: 200 },
    { key: 'total_cif_value', label: 'Total cif value', width: 200 },
    { key: 'total_fob_value', label: 'Total fob value', width: 200 },
	{ key: 'fob_value_currency', label: 'FOB Value Currency', width: 200 },
    { key: 'total_fob_value_pkr', label: 'Total fob value PKR', width: 200 },
    { key: 'total_fob_value_usd', label: 'Total fob value USD', width: 200 },
    { key: 'total_invoice_value', label: 'Total Invoice Value', width: 200 },
    { key: 'total_invoice_value_cif_usd', label: 'Total Invoice Value CIF USD', width: 200 },
    { key: 'total_value_etb', label: 'Total Value ETB', width: 200 },
    { key: 'transport_doc_date', label: 'Transport doc Date', width: 200 },
    { key: 'transport_doc_no', label: 'Transport doc No', width: 200 },
    { key: 'unit_price', label: 'Unit Price', width: 100 },
    { key: 'variety', label: 'Variety', width: 200 },
    { key: 'sb_date', label: 'SB_Date', width: 150 },  
    { key: 'product', label: 'Product', width: 200 },
    { key: 'qty', label: 'QTY', width: 100 },
    { key: 'unit_rate_in_foreign_currency', label: 'Unit Rate in Foreign Currency', width: 180 },
    { key: 'unit_rate_currency', label: 'Unit Rate Currency', width: 100 },
    { key: 'total_sb_value_in_inr_in_lacs', label: 'Total SB Value in INR in Lacs', width: 200 },
    { key: 'value_in_fc', label: 'Value IN FC', width: 200 },
    { key: 'port_of_destination', label: 'Port of Destination', width: 200 },
    { key: 'city_of_destination', label: 'City of Destination', width: 200 },
    { key: 'port_of_origin', label: 'Port of Origin', width: 200 },
    { key: 'indian_exportar_name', label: 'Indian Exporter Name', width: 200 },
    { key: 'exporter_add1', label: 'Exporter Add1', width: 200 },
    { key: 'exporter_add2', label: 'Exporter Add2', width: 200 },
    { key: 'exporter_city', label: 'Exporter City', width: 200 },
    { key: 'for_add1', label: 'FOR_Add1', width: 200 },
    { key: 'for_add2', label: 'FOR_Add2', width: 200 },
    { key: 'for_add3', label: 'FOR_Add3', width: 200 },
    { key: 'for_add4', label: 'FOR_Add4', width: 200 },
    { key: 'importer_country', label: 'Importer Country', width: 200 },   
    { key: 'hs2', label: 'HS2', width: 200 },
    { key: 'hs4', label: 'HS4', width: 200 },
    { key: 'cur_que', label: 'Cur_que', width: 200 },
    { key: 'invoice_no', label: 'Invoice_no', width: 200 },
    { key: 'invoice_srl_no', label: 'Invoice Srl No', width: 200 },
    { key: 'challan_no', label: 'Challan No', width: 200 },
    { key: 'draw_back', label: 'DRAW BACK', width: 200 },
    { key: 'raw_port', label: 'RAW_PORT', width: 200 },
    { key: 'cush', label: 'CUSH', width: 200 },
    { key: 'invoice_date', label: 'Invoice_Date', width: 200 },
    { key: 'cha_no', label: 'CHA_NO', width: 200 },
    { key: 'cha_name', label: 'CHA_NAME', width: 200 },
    { key: 'for_port_code', label: 'For_Port_Code', width: 200 },
    { key: 'leo_date', label: 'LEO_Date', width: 200 },
    { key: 'country_code', label: 'CountryCode', width: 200 },
    { key: 'drawback_excise', label: 'Drawback_Excise', width: 200 },
    { key: 'drawback_customs', label: 'Drawback_Customs', width: 200 },
    { key: 'total_invoice_value_usd', label: 'Total Invoice Value USD', width: 200 },
    //{ key: 'exporter_phone_email', label: 'Exporter Phone Email', width: 200 },  
    { key: 'total_value_bdt', label: 'Total Value BDT', width: 200 },
    { key: 'total_value_uah', label: 'Total Value UAH', width: 200 },   
    { key: 'total_value_kes', label: 'Total Value KES', width: 200 },
    { key: 'total_value_lsl', label: 'Total Value LSL', width: 200 },
    { key: 'total_value_naira', label: 'Total Value NAIRA', width: 200 },
    { key: 'total_value_usd', label: 'Total Value USD', width: 200 },    
    { key: 'unit_price_usd', label: 'Unit Price USD', width: 200 },
    { key: 'unit_price_bdt', label: 'Unit Price_BDT', width: 100 },
    { key: 'unit_price_kes', label: 'Unit Price_KES', width: 100 },
    { key: 'duty_mod', label: 'Duty Mod', width: 200 },
    { key: 'duty', label: 'Duty', width: 200 },   
    { key: 'bl_date', label: 'BL Date', width: 200 }, 
    { key: 'menifest_no', label: 'Menifest No.', width: 200 },
    { key: 'menifest_date', label: 'Menifest Date', width: 200 },
    { key: 'incoterms', label: 'Incoterms', width: 200 },
    { key: 'vat_preference', label: 'VAT Preference', width: 200 },
    { key: 'total_freight_value', label: 'Total Freight Value', width: 200 },
    { key: 'total_insurance_value', label: 'Total Insurance Value', width: 200 },
    { key: 'total_value_cif_naira', label: 'Total Value CIF NAIRA', width: 200 },
    { key: 'total_value_cif_ugx', label: 'Total Value CIF UGX', width: 200 },
    { key: 'total_value_cif_usd', label: 'Total Value CIF USD', width: 200 },
    { key: 'total_value_cif_zwd', label: 'Total Value CIF ZWD', width: 200 },
    { key: 'total_value_cif', label: 'Total Value CIF', width: 200 },
    { key: 'cif_unit_price', label: 'CIF Unit Price', width: 200 },
    { key: 'assessed_value', label: 'Assessed Value', width: 200 },
    { key: 'assed_unit_value', label: 'Assed Unit Value', width: 200 },
    { key: 'assessed_import_value_pkr', label: 'Assessed Import Value PKR', width: 200 },
    { key: 'custom_clearing_agent_code', label: 'Custom Clearing Agent Code', width: 200 },
    { key: 'custom_clearing_agent', label: 'Custom Clearing Agent', width: 200 },
    //{ key: 'custom_clearing_agent_address', label: 'Custom Clearing Agent Address', width: 200 },
    { key: 'custom_clearing_office', label: 'Custom Clearing Office', width: 200 },
    { key: 'import_purpose', label: 'Import Purpose', width: 200 },
    { key: 'import_purpose_group', label: 'Import Purpose Group', width: 200 },
    { key: 'mode_of_payment', label: 'Mode of Payment', width: 200 },
    { key: 'tax_percentge', label: 'Tax Percentge', width: 200 },
    { key: 'importer_economic_key', label: 'Importer Economic Key', width: 200 },
    { key: 'commercial_deposit', label: 'Commercial Deposit', width: 200 },
    { key: 'value_added_tax_mod', label: 'Value Added Tax Mod', width: 200 },
    { key: 'value_added_tax_amount', label: 'Value Added Tax Amount', width: 200 },
    { key: 'income_tax_mod', label: 'Income Tax Mod', width: 200 },
    { key: 'income_tax_amount', label: 'Income Tax Amount', width: 200 },
    { key: 'luxury_tax_mod', label: 'Luxury Tax Mod', width: 200 },
    { key: 'manufacture_year', label: 'Manufacture Year', width: 200 },
    

];

export const columnListExportDownloadUSA = [
    { key: 'id', label: 'Sl', width: 100 },
	{ key: 'date', label: 'Date', width: 150 },
	{ key: 'hs_code', label: 'HSCODE', width: 150 },
    { key: 'product_description', label: 'Product Description', width: 200 },
	{ key: 'exporter_name', label: 'Exporter Name', width: 200 },
	{ key: 'exporter_address', label: 'Exporter Address', width: 200 },
	{ key: 'recepient_name', label: 'Recepient Name', width: 200 },
	{ key: 'recepient_address', label: 'Recepient Address', width: 200 },
	{ key: 'notify_party_name', label: 'Notify Party Name', width: 200 },
    { key: 'destination_country', label: 'Destination Country', width: 150 },
	{ key: 'destination_port', label: 'Destination Port', width: 150 },
    { key: 'origin_port', label: 'Origin Port', width: 200 },
    { key: 'ship_name', label: 'Ship Name', width: 100 },
    { key: 'net_weight', label: 'Net Weight', width: 150 },
    { key: 'net_weight_unit', label: 'Net Weight Unit', width: 100 },
    { key: 'place_of_receipt', label: 'Place of Receipt', width: 200 },
    { key: 'bl_number', label: 'BL Number', width: 200 },
    { key: 'quantity', label: 'Quantity', width: 200 },
	{ key: 'unit', label: 'Unit', width: 80 },
	{ key: 'transport_company', label: 'Transport Company', width: 200 },
    { key: 'hs_description', label: 'HS Description', width: 200 },
    { key: 'quantity_of_container', label: 'Quantity of Container', width: 200 },
	{ key: 'voyage_number', label: 'Voyage Number', width: 200 },
    { key: 'type_of_cargo', label: 'Cargo Type', width: 100 },
    { key: 'year', label: 'Year', width: 100 },
    { key: 'month', label: 'Month', width: 200 },
	{ key: 'hs_code2', label: 'HD Code2', width: 150 },
    { key: 'hs_code4', label: 'HS Code4', width: 150 },
    { key: 'commercial_quantity_unit', label: 'Commercial Quantity Unit', width: 200 },
    { key: 'attributes', label: 'Attributes', width: 200 },
    { key: 'brand', label: 'Brand', width: 200 },
    { key: 'category', label: 'Category', width: 200 },
    { key: 'cif_value_botswanan_pula', label: 'cif_value_botswanan_pula', width: 200 },
    { key: 'cif_value_naira', label: 'cif_value_naira', width: 200 },
    { key: 'cif_value_ugx', label: 'cif_value_ugx', width: 200 },
    { key: 'clearance_fee', label: 'Clearance Fee', width: 200 },
    { key: 'clearing_agent', label: 'Clearing Agent', width: 200 },
    { key: 'commercial_quantity', label: 'Commercial Quantity', width: 200 },
    { key: 'condition', label: 'Condition', width: 200 },
    { key: 'conocimiento_emb', label: 'Conocimiento Emb', width: 200 },
    { key: 'container', label: 'Container', width: 200 },
    { key: 'currency', label: 'Currency', width: 200 },
    { key: 'customs_clearance_office', label: 'Customs Clearance Office', width: 200 },
    { key: 'declarant', label: 'Declarant', width: 100 },
    { key: 'declarant_code', label: 'Declarant Code', width: 100 },
    { key: 'declaration_type', label: 'Declaration Type', width: 200 },
    { key: 'destination_port_code', label: 'Destination Port Code', width: 100 },
    { key: 'economic_zone', label: 'Economic Zone', width: 200 },
    { key: 'exchange_rate', label: 'Exchange Rate', width: 200 },
    { key: 'export_country', label: 'Export Country', width: 200 },
    { key: 'export_purpose', label: 'Export Purpose', width: 200 },
    { key: 'export_purpose_group', label: 'Export Purpose Group', width: 200 },
    { key: 'fob_value_botswanan_pula', label: 'fob_value_botswanan_pula', width: 200 },
    { key: 'fob_value_naira', label: 'fob_value_naira', width: 200 },
    { key: 'freight_value', label: 'Freight Value', width: 200 },
    { key: 'freight_value_botswanan_pula', label: 'Freight Value botswanan_pula', width: 200 },
    { key: 'freight_value_usd', label: 'Freight Value USD', width: 200 },
    { key: 'gross_weight', label: 'Gross Weight', width: 200 },
    { key: 'gross_weight_unit', label: 'Gross Weight Unit', width: 200 },
    { key: 'hs_code6', label: 'HS Code6', width: 150 },
    { key: 'iec', label: 'IEC', width: 200 },
    { key: 'incoterm', label: 'Incoterm', width: 200 },
    { key: 'insurance_value', label: 'Insurance Value', width: 200 },
    { key: 'insurance_value_botswanan_pula', label: 'insurance_value_botswanan_pula', width: 200 },
    { key: 'insurance_value_usd', label: 'Insurance Value USD', width: 200 },
    { key: 'item_no', label: 'Item_no', width: 200 },
    { key: 'item_value_cif', label: 'Item Value CIF', width: 200 },
    { key: 'item_value_fob', label: 'Item Value Fob', width: 200 },
    { key: 'licence_code', label: 'Licence Code', width: 200 },
    { key: 'mode_of_transport', label: 'Mode of Transport', width: 200 },
    { key: 'no_of_package', label: 'No Of Package', width: 200 },
    { key: 'notify_party_code', label: 'Notify Party Code', width: 100 },
    { key: 'origin_country', label: 'Country of Origin', width: 200 },
    { key: 'origin_port_code', label: 'Port of Origin Code', width: 200 },
    { key: 'package_description', label: 'Package Description', width: 200 },
    { key: 'package_type', label: 'Package Type', width: 200 },
    { key: 'payment_mode', label: 'Place of Discharge', width: 200 },
    { key: 'purpose_consumption', label: 'Purpose of Consumption', width: 200 },
    { key: 'sb_no', label: 'SB_NO', width: 100 },
    { key: 'std_quantity', label: 'Std Quantity', width: 200 },
    { key: 'std_unit', label: 'Std Unit', width: 200 },
    { key: 'subitem_no', label: 'Subitem_no', width: 200 },
    { key: 'tax_amount', label: 'Tax Amount', width: 200 },
    { key: 'total_cif_value', label: 'Total cif value', width: 200 },
    { key: 'total_fob_value', label: 'Total fob value', width: 200 },
	{ key: 'fob_value_currency', label: 'FOB Value Currency', width: 200 },
    { key: 'total_fob_value_pkr', label: 'Total fob value PKR', width: 200 },
    { key: 'total_fob_value_usd', label: 'Total fob value USD', width: 200 },
    { key: 'total_invoice_value', label: 'Total Invoice Value', width: 200 },
    { key: 'total_invoice_value_cif_usd', label: 'Total Invoice Value CIF USD', width: 200 },
    { key: 'total_value_etb', label: 'Total Value ETB', width: 200 },
    { key: 'transport_doc_date', label: 'Transport doc Date', width: 200 },
    { key: 'transport_doc_no', label: 'Transport doc No', width: 200 },
    { key: 'unit_price', label: 'Unit Price', width: 100 },
    { key: 'variety', label: 'Variety', width: 200 },
    { key: 'sb_date', label: 'SB_Date', width: 150 },  
    { key: 'product', label: 'Product', width: 200 },
    { key: 'qty', label: 'QTY', width: 100 },
    { key: 'unit_rate_in_foreign_currency', label: 'Unit Rate in Foreign Currency', width: 180 },
    { key: 'unit_rate_currency', label: 'Unit Rate Currency', width: 100 },
    { key: 'total_sb_value_in_inr_in_lacs', label: 'Total SB Value in INR in Lacs', width: 200 },
    { key: 'value_in_fc', label: 'Value IN FC', width: 200 },
    { key: 'port_of_destination', label: 'Port of Destination', width: 200 },
    { key: 'city_of_destination', label: 'City of Destination', width: 200 },
    { key: 'port_of_origin', label: 'Port of Origin', width: 200 },
    { key: 'indian_exportar_name', label: 'Indian Exporter Name', width: 200 },
    { key: 'exporter_add1', label: 'Exporter Add1', width: 200 },
    { key: 'exporter_add2', label: 'Exporter Add2', width: 200 },
    { key: 'exporter_city', label: 'Exporter City', width: 200 },  
    { key: 'foreign_importer_name', label: 'Foreign Importer Name', width: 200 },
    { key: 'for_add1', label: 'FOR_Add1', width: 200 },
    { key: 'for_add2', label: 'FOR_Add2', width: 200 },
    { key: 'for_add3', label: 'FOR_Add3', width: 200 },
    { key: 'for_add4', label: 'FOR_Add4', width: 200 },	
	{ key: 'importer_country', label: 'Importer Country', width: 200 },
    { key: 'hs2', label: 'HS2', width: 200 },
    { key: 'hs4', label: 'HS4', width: 200 },
    { key: 'cur_que', label: 'Cur_que', width: 200 },
    { key: 'invoice_no', label: 'Invoice_no', width: 200 },
    { key: 'invoice_srl_no', label: 'Invoice Srl No', width: 200 },
    { key: 'challan_no', label: 'Challan No', width: 200 },
    { key: 'draw_back', label: 'DRAW BACK', width: 200 },
    { key: 'raw_port', label: 'RAW_PORT', width: 200 },
    { key: 'cush', label: 'CUSH', width: 200 },
    { key: 'invoice_date', label: 'Invoice_Date', width: 200 },
    { key: 'cha_no', label: 'CHA_NO', width: 200 },
    { key: 'cha_name', label: 'CHA_NAME', width: 200 },
    { key: 'for_port_code', label: 'For_Port_Code', width: 200 },
    { key: 'leo_date', label: 'LEO_Date', width: 200 },
    { key: 'country_code', label: 'CountryCode', width: 200 },
    { key: 'drawback_excise', label: 'Drawback_Excise', width: 200 },
    { key: 'drawback_customs', label: 'Drawback_Customs', width: 200 },
    { key: 'total_invoice_value_usd', label: 'Total Invoice Value USD', width: 200 },
    { key: 'exporter_phone_email', label: 'Exporter Phone Email', width: 200 },  
    { key: 'total_value_bdt', label: 'Total Value BDT', width: 200 },
    { key: 'total_value_uah', label: 'Total Value UAH', width: 200 },   
    { key: 'total_value_kes', label: 'Total Value KES', width: 200 },
    { key: 'total_value_lsl', label: 'Total Value LSL', width: 200 },
    { key: 'total_value_naira', label: 'Total Value NAIRA', width: 200 },
    { key: 'total_value_usd', label: 'Total Value USD', width: 200 },    
    { key: 'unit_price_usd', label: 'Unit Price USD', width: 200 },
    { key: 'unit_price_bdt', label: 'Unit Price_BDT', width: 100 },
    { key: 'unit_price_kes', label: 'Unit Price_KES', width: 100 },
    { key: 'duty_mod', label: 'Duty Mod', width: 200 },
    { key: 'duty', label: 'Duty', width: 200 },   
    { key: 'bl_date', label: 'BL Date', width: 200 }, 
    { key: 'menifest_no', label: 'Menifest No.', width: 200 },
    { key: 'menifest_date', label: 'Menifest Date', width: 200 },
    { key: 'incoterms', label: 'Incoterms', width: 200 },
    { key: 'vat_preference', label: 'VAT Preference', width: 200 },
    { key: 'total_freight_value', label: 'Total Freight Value', width: 200 },
    { key: 'total_insurance_value', label: 'Total Insurance Value', width: 200 },
    { key: 'total_value_cif_naira', label: 'Total Value CIF NAIRA', width: 200 },
    { key: 'total_value_cif_ugx', label: 'Total Value CIF UGX', width: 200 },
    { key: 'total_value_cif_usd', label: 'Total Value CIF USD', width: 200 },
    { key: 'total_value_cif_zwd', label: 'Total Value CIF ZWD', width: 200 },
    { key: 'total_value_cif', label: 'Total Value CIF', width: 200 },
    { key: 'cif_unit_price', label: 'CIF Unit Price', width: 200 },
    { key: 'assessed_value', label: 'Assessed Value', width: 200 },
    { key: 'assed_unit_value', label: 'Assed Unit Value', width: 200 },
    { key: 'assessed_import_value_pkr', label: 'Assessed Import Value PKR', width: 200 },
    { key: 'custom_clearing_agent_code', label: 'Custom Clearing Agent Code', width: 200 },
    { key: 'custom_clearing_agent', label: 'Custom Clearing Agent', width: 200 },
    { key: 'custom_clearing_agent_address', label: 'Custom Clearing Agent Address', width: 200 },
    { key: 'custom_clearing_office', label: 'Custom Clearing Office', width: 200 },
    { key: 'import_purpose', label: 'Import Purpose', width: 200 },
    { key: 'import_purpose_group', label: 'Import Purpose Group', width: 200 },
    { key: 'mode_of_payment', label: 'Mode of Payment', width: 200 },
    { key: 'tax_percentge', label: 'Tax Percentge', width: 200 },
    { key: 'importer_economic_key', label: 'Importer Economic Key', width: 200 },
    { key: 'commercial_deposit', label: 'Commercial Deposit', width: 200 },
    { key: 'value_added_tax_mod', label: 'Value Added Tax Mod', width: 200 },
    { key: 'value_added_tax_amount', label: 'Value Added Tax Amount', width: 200 },
    { key: 'income_tax_mod', label: 'Income Tax Mod', width: 200 },
    { key: 'income_tax_amount', label: 'Income Tax Amount', width: 200 },
    { key: 'luxury_tax_mod', label: 'Luxury Tax Mod', width: 200 },
    { key: 'manufacture_year', label: 'Manufacture Year', width: 200 },
    

];


export const columnListImportDownloadUSA = [
    { key: 'id', label: 'Sl', width: 100 },
	{ key: 'be_date', label: 'Date', width: 200 },
    { key: 'hs_code', label: 'HSCODE', width: 100 },
    { key: 'product_description', label: 'Product Description', width: 200 },
    { key: 'importer_name', label: 'Importer Name', width: 200 },
	{ key: 'importer_address', label: 'Importer Address', width: 200 },
    { key: 'exporter_name', label: 'Exporter Name', width: 200 },
	{ key: 'exporter_address', label: 'Exporter Address', width: 200 },
    { key: 'notify_party_name', label: 'Notify Party Name', width: 200 },
	{ key: 'notify_party_address', label: 'Notify Party Address', width: 200 },	
	{ key: 'net_weight', label: 'Net Weight', width: 100 },
	{ key: 'net_weight_unit', label: 'Net Weight Unit', width: 100 },
	{ key: 'quantity', label: 'Quantity', width: 100 },
    { key: 'unit', label: 'UNIT', width: 200 },
    { key: 'origin_country', label: 'Country of Origin', width: 200 },
    { key: 'origin_port', label: 'Origin Port', width: 200 },
	{ key: 'destination_port', label: 'Destination Port', width: 200 },
	{ key: 'place_of_receipt', label: 'Place of Receipt', width: 200 },
    { key: 'bl_number', label: 'BL Number', width: 200 },
    { key: 'container', label: 'Container', width: 200 },
    { key: 'menifest_no', label: 'Menifest No.', width: 200 },
    { key: 'mode_of_transport', label: 'Mode of Transport', width: 200 },
	{ key: 'ship_name', label: 'Ship Name', width: 100 },
    { key: 'transport_company', label: 'Transport Company', width: 200 },
    { key: 'total_value_cif_usd', label: 'Total Value CIF USD', width: 200 },
	{ key: 'month', label: 'Month', width: 200 },
    { key: 'year', label: 'Year', width: 100 },
    { key: 'hs_code2', label: 'HD Code2', width: 200 },
    { key: 'hs_code4', label: 'HS Code4', width: 200 },
    { key: 'assed_unit_value', label: 'Assed Unit Value', width: 200 },
    { key: 'assessed_import_value_pkr', label: 'Assessed Import Value PKR', width: 200 },
    { key: 'assessed_value', label: 'Assessed Value', width: 200 },
	{ key: 'assessed_value_currency', label: 'Assessed Value Currency', width: 200 },
    { key: 'attributes', label: 'Attributes', width: 200 },
    { key: 'be_no', label: 'BE VO.', width: 100 },
    { key: 'bl_date', label: 'BL Date', width: 200 },
    { key: 'brand', label: 'Brand', width: 200 },
    { key: 'cif_unit_price', label: 'CIF Unit Price', width: 200 },
    { key: 'cif_value_botswanan_pula', label: 'cif_value_botswanan_pula', width: 200 },
    { key: 'commercial_deposit', label: 'Commercial Deposit', width: 200 },
    { key: 'commercial_quantity', label: 'Commercial Quantity', width: 200 },
    { key: 'commercial_quantity_unit', label: 'Commercial Quantity Unit', width: 200 },
    { key: 'condition', label: 'Condition', width: 200 },
    { key: 'conocimiento_emb', label: 'Conocimiento Emb', width: 200 },
    { key: 'currency', label: 'Currency', width: 200 },
    { key: 'custom_clearing_agent', label: 'Custom Clearing Agent', width: 200 },
    { key: 'custom_clearing_agent_address', label: 'Custom Clearing Agent Address', width: 200 },
    { key: 'custom_clearing_agent_code', label: 'Custom Clearing Agent Code', width: 200 },
    { key: 'custom_clearing_office', label: 'Custom Clearing Office', width: 200 },
    { key: 'declarant', label: 'Declarant', width: 100 },
    { key: 'declarant_code', label: 'Declarant Code', width: 100 },
    { key: 'declaration_type', label: 'Declaration Type', width: 200 },
    { key: 'delivery_term_place', label: 'Delivery Term Place', width: 200 },
    { key: 'destination_country', label: 'Destination Country', width: 150 },
    { key: 'destination_port_code', label: 'Destination Port Code', width: 100 },
    { key: 'duty', label: 'Duty', width: 200 },  
    { key: 'duty_mod', label: 'Duty Mod', width: 200 },
    { key: 'economic_zone', label: 'Economic Zone', width: 200 },
    { key: 'exchange_rate', label: 'Exchange Rate', width: 200 },
    { key: 'export_country', label: 'Export Country', width: 200 },
    { key: 'exporter_phone_email', label: 'Exporter Phone Email', width: 200 },
    { key: 'fob_value_botswanan_pula', label: 'fob_value_botswanan_pula', width: 200 },
    { key: 'freight_value_botswanan_pula', label: 'Freight Value botswanan_pula', width: 200 },
    { key: 'freight_value_usd', label: 'Freight Value USD', width: 200 },
    { key: 'gross_weight', label: 'Gross Weight', width: 200 },
    { key: 'gross_weight_unit', label: 'Gross Weight Unit', width: 200 }, 
    { key: 'hs_code6', label: 'HS Code6', width: 200 },
    { key: 'hs_description', label: 'HS Description', width: 200 },
    { key: 'iec', label: 'IEC', width: 100 },
    { key: 'import_purpose', label: 'Import Purpose', width: 200 },
    { key: 'import_purpose_group', label: 'Import Purpose Group', width: 200 },
    { key: 'importer_economic_key', label: 'Importer Economic Key', width: 200 },
    { key: 'importer_email', label: 'Importer Email', width: 100 },
    { key: 'importer_phone', label: 'Importer Phone', width: 100 },
    { key: 'income_tax_amount', label: 'Income Tax Amount', width: 100 },
    { key: 'income_tax_mod', label: 'Income Tax Mod', width: 200 },
    { key: 'incoterms', label: 'Incoterms', width: 200 },
    { key: 'insurance_value_botswanan_pula', label: 'insurance_value_botswanan_pula', width: 200 },
    { key: 'insurance_value_usd', label: 'Insurance Value USD', width: 200 },
    { key: 'item_no', label: 'Item No', width: 200 },
    { key: 'item_value_cif', label: 'Item Value CIF', width: 200 },
    { key: 'item_value_fob', label: 'Item Value Fob', width: 200 },
    { key: 'luxury_tax_mod', label: 'Luxury Tax Mod', width: 200 },
    { key: 'manufacture_year', label: 'Manufacture Year', width: 200 },
    { key: 'menifest_date', label: 'Menifest Date', width: 200 },
    { key: 'mode_of_payment', label: 'Mode of Payment', width: 200 },
    { key: 'no_of_package', label: 'No Of Package', width: 200 },
    { key: 'notify_party_code', label: 'Notify Party Code', width: 100 },
    { key: 'origin_port_code', label: 'Port of Origin Code', width: 200 },  
    { key: 'package_description', label: 'Package Description', width: 200 },
    { key: 'package_type', label: 'Package Type', width: 200 },  
    { key: 'std_quantity', label: 'Std Quantity', width: 200 },
    { key: 'std_unit', label: 'Std Unit', width: 200 },
    { key: 'subitem_no', label: 'Subitem_no', width: 200 },
    { key: 'tax_amount', label: 'Tax Amount', width: 200 },
    { key: 'tax_percentge', label: 'Tax Percentge', width: 200 },
    { key: 'total_freight_value', label: 'Total Freight Value', width: 200 },
    { key: 'total_insurance_value', label: 'Total Insurance Value', width: 200 },
    { key: 'total_invoice_value', label: 'Total Invoice Value', width: 200 },
    { key: 'total_invoice_value_usd', label: 'Total Invoice Value USD', width: 200 },
    { key: 'total_value', label: 'Total Value', width: 100 },
    { key: 'total_value_bdt', label: 'Total Value_BDT', width: 100 },
    { key: 'total_value_cif', label: 'Total Value CIF', width: 200 },
    { key: 'total_value_cif_naira', label: 'Total Value CIF NAIRA', width: 200 },
    { key: 'total_value_cif_ugx', label: 'Total Value CIF UGX', width: 200 },
    { key: 'total_value_cif_zwd', label: 'Total Value CIF ZWD', width: 200 },
    { key: 'total_value_etb', label: 'Total Value ETB', width: 200 },
    { key: 'total_value_kes', label: 'Total Value KES', width: 200 },
    { key: 'total_value_lsl', label: 'Total Value LSL', width: 200 },
    { key: 'total_value_naira', label: 'Total Value NAIRA', width: 200 },
    { key: 'total_value_uah', label: 'Total Value UAH', width: 200 },
    { key: 'total_value_usd', label: 'Total Value USD', width: 200 },
    { key: 'transport_doc_date', label: 'Transport doc Date', width: 200 },
    { key: 'transport_doc_no', label: 'Transport doc No', width: 200 },
    { key: 'unit_price', label: 'Unit Price', width: 100 },
    { key: 'unit_price_bdt', label: 'Unit Price_BDT', width: 100 },
    { key: 'unit_price_kes', label: 'Unit Price_KES', width: 100 },
    { key: 'unit_price_usd', label: 'Unit Price USD', width: 200 }, 
    { key: 'value_added_tax_amount', label: 'Value Added Tax Amount', width: 200 },
    { key: 'value_added_tax_mod', label: 'Value Added Tax Mod', width: 200 },
    { key: 'variety', label: 'Variety', width: 200 },
    { key: 'vat_preference', label: 'VAT Preference', width: 200 },
    { key: 'importer', label: 'Indian Company Name', width: 200 },
    { key: 'address1', label: 'ADDRESS1', width: 200 },
    { key: 'address2', label: 'ADDRESS2', width: 200 },
    { key: 'city', label: 'CITY', width: 200 },
    { key: 'pin', label: 'PIN', width: 200 },
    { key: 'prod_desc', label: 'PROD_DESC', width: 200 },
    { key: 'ind_port', label: 'IND_PORT', width: 200 },
    { key: 'for_count', label: 'FOR_COUNT', width: 200 },
    { key: 'for_port', label: 'FOR_PORT', width: 200 },
    { key: 'value', label: 'VALUE', width: 50 },
    { key: 'value_usd', label: 'VALUE(US$)', width: 50 },
    { key: 'qty', label: 'QTY', width: 200 },   
    { key: 'val_duty_inr', label: 'VAL_DUTY_INR', width: 200 },
    { key: 'val_duty_usd', label: 'VAL_DUTY_USD', width: 200 },
    { key: 'applicable_duty', label: 'Applicable DUTY', width: 200 },
    { key: 'unit_rate_inr', label: 'UNIT RATE(IND RS)', width: 200 },
    { key: 'ship_mode', label: 'SHIP_MODE', width: 200 },
    { key: 'unit_rate_usd', label: 'UNIT RATE(USD)', width: 200 },
    { key: 'beno', label: 'BENO', width: 200 },
    { key: 'cha_no', label: 'CHA_NO', width: 200 },
    { key: 'cha_name', label: 'CHA_NAME', width: 200 },
    { key: 'actual_duty', label: 'Actual DUTY', width: 200 },
    { key: 'total_duty_on_entire_be', label: 'Total Duty On Entire BE', width: 200 },
    { key: 'typ', label: 'Typ', width: 200 },
    { key: 'ag', label: 'AG', width: 200 },
    { key: 'inv_no', label: 'Inv No', width: 200 },
    { key: 'unitprice_fc', label: 'UnitPrice FC', width: 200 },  
    { key: 'foreign_exporter', label: 'Foreign Exporter', width: 200 },
    { key: 'foreign_address', label: 'Foreign Address', width: 200 },
    { key: 'date', label: 'Date', width: 130 },
    { key: 'cush', label: 'CUSH', width: 200 },
    { key: 'location', label: 'Location', width: 200 },
    { key: 'inv_date', label: 'Inv_Date', width: 200 },
    { key: 'inv_srl_no', label: 'Inv_Srl_No', width: 200 },
    { key: 'inv_value', label: 'Inv_Value', width: 200 },
    { key: 'forcntry_code', label: 'ForCntry_Code', width: 200 },
    { key: 'forport_code', label: 'FORPORT_Code', width: 200 },
    { key: 'shipmentportcode', label: 'Shipment Port Code', width: 200 },
    { key: 'shipmentport', label: 'Shipment Port', width: 200 },
    { key: 'bcd_notn', label: 'BCD Notn', width: 200 },
    { key: 'bcd_rate', label: 'BCD Rate', width: 200 },
    { key: 'bcd_amt', label: 'BCD Amt', width: 200 },
    { key: 'cvd_notn', label: 'CVD Notn', width: 200 },
    { key: 'cvd_rate', label: 'CVD Rate', width: 200 },
    { key: 'cvd_amt', label: 'CVD Amt', width: 200 },
    { key: 'igst_amt', label: 'IGST Amt', width: 200 },
    { key: 'gst_cess_amt', label: 'GST Cess Amt', width: 200 },
    { key: 'BE_Date', label: 'BE_Date', width: 150 },
    { key: 'BE_NO', label: 'BE_NO', width: 100 },
    
    
];


export const columnListImportForeign = [
    // { key: 'id', label: 'Sl', width: 100 },
	{ key: 'be_date', label: 'Date', width: 200 },
	{ key: 'hs_code', label: 'HSCODE', width: 100 },
    { key: 'product_description', label: 'Product Description', width: 200 },
    { key: 'importer_name', label: 'Importer Name', width: 200 },
    { key: 'exporter_name', label: 'Exporter Name', width: 200 },
    { key: 'notify_party_name', label: 'Notify Party Name', width: 200 },	
    { key: 'origin_country', label: 'Country of Origin', width: 200 },
	{ key: 'net_weight', label: 'Net Weight', width: 100 },
	{ key: 'net_weight_unit', label: 'Net Weight Unit', width: 100 },
	{ key: 'quantity', label: 'Quantity', width: 100 },
    { key: 'unit', label: 'UNIT', width: 200 },
	{ key: 'total_value_usd', label: 'Total Value USD', width: 200 },
    { key: 'unit_price_usd', label: 'Unit Price USD', width: 200 }, 
	{ key: 'total_value', label: 'Total Value', width: 100 },
 	{ key: 'currency', label: 'Currency', width: 200 },
	{ key: 'unit_price', label: 'Unit Price', width: 100 },
    { key: 'origin_port', label: 'Origin Port', width: 200 },
	{ key: 'destination_port', label: 'Destination Port', width: 200 },
    { key: 'total_value_cif_usd', label: 'Total Value CIF USD', width: 200 },
    { key: 'cif_unit_price', label: 'CIF Unit Price', width: 200 },
    { key: 'bl_number', label: 'BL Number', width: 200 },
    { key: 'container', label: 'Container', width: 200 },
    { key: 'menifest_no', label: 'Menifest No.', width: 200 },
    { key: 'mode_of_transport', label: 'Mode of Transport', width: 200 },
	{ key: 'ship_name', label: 'Ship Name', width: 100 },
    { key: 'transport_company', label: 'Transport Company', width: 200 },
	{ key: 'month', label: 'Month', width: 200 },
    { key: 'year', label: 'Year', width: 100 },
    { key: 'hs_code2', label: 'HS Code2', width: 200 },
    { key: 'hs_code4', label: 'HS Code4', width: 200 },
	{ key: 'hs_description', label: 'HS Description', width: 200 },
    { key: 'gross_weight', label: 'Gross Weight', width: 200 },
    { key: 'gross_weight_unit', label: 'Gross Weight Unit', width: 200 }, 
	//{ key: 'notify_party_address', label: 'Notify Party Address', width: 200 },
	//{ key: 'importer_address', label: 'Importer Address', width: 200 },
	//{ key: 'exporter_address', label: 'Exporter Address', width: 200 },
    { key: 'assed_unit_value', label: 'Assed Unit Value', width: 200 },
    { key: 'assessed_import_value_pkr', label: 'Assessed Import Value PKR', width: 200 },
    { key: 'assessed_value', label: 'Assessed Value', width: 200 },
	{ key: 'assessed_value_currency', label: 'Assessed Value Currency', width: 200 },
    { key: 'attributes', label: 'Attributes', width: 200 },
    { key: 'be_no', label: 'BE VO.', width: 100 },
    { key: 'bl_date', label: 'BL Date', width: 200 },
    { key: 'brand', label: 'Brand', width: 200 },
    { key: 'total_value_cif', label: 'Total Value CIF', width: 200 },
    { key: 'cif_value_botswanan_pula', label: 'cif_value_botswanan_pula', width: 200 },
    { key: 'commercial_deposit', label: 'Commercial Deposit', width: 200 },
    { key: 'commercial_quantity', label: 'Commercial Quantity', width: 200 },
    { key: 'commercial_quantity_unit', label: 'Commercial Quantity Unit', width: 200 },
    { key: 'condition', label: 'Condition', width: 200 },
    { key: 'conocimiento_emb', label: 'Conocimiento Emb', width: 200 },
    { key: 'custom_clearing_agent', label: 'Custom Clearing Agent', width: 200 },
    //{ key: 'custom_clearing_agent_address', label: 'Custom Clearing Agent Address', width: 200 },
    { key: 'custom_clearing_agent_code', label: 'Custom Clearing Agent Code', width: 200 },
    { key: 'custom_clearing_office', label: 'Custom Clearing Office', width: 200 },
    { key: 'declarant', label: 'Declarant', width: 100 },
    { key: 'declarant_code', label: 'Declarant Code', width: 100 },
    { key: 'declaration_type', label: 'Declaration Type', width: 200 },
    { key: 'delivery_term_place', label: 'Delivery Term Place', width: 200 },
    { key: 'destination_country', label: 'Destination Country', width: 150 },
    { key: 'destination_port_code', label: 'Destination Port Code', width: 100 },
    { key: 'duty', label: 'Duty', width: 200 },  
    { key: 'duty_mod', label: 'Duty Mod', width: 200 },
    { key: 'economic_zone', label: 'Economic Zone', width: 200 },
    { key: 'exchange_rate', label: 'Exchange Rate', width: 200 },
    { key: 'export_country', label: 'Export Country', width: 200 },
    //{ key: 'exporter_phone_email', label: 'Exporter Phone Email', width: 200 },
    { key: 'hs_code6', label: 'HS Code6', width: 200 },
    { key: 'iec', label: 'IEC', width: 100 },
    { key: 'import_purpose', label: 'Import Purpose', width: 200 },
    { key: 'import_purpose_group', label: 'Import Purpose Group', width: 200 },
    { key: 'importer_economic_key', label: 'Importer Economic Key', width: 200 },
    //{ key: 'importer_email', label: 'Importer Email', width: 100 },
    //{ key: 'importer_phone', label: 'Importer Phone', width: 100 },
    { key: 'income_tax_amount', label: 'Income Tax Amount', width: 100 },
    { key: 'income_tax_mod', label: 'Income Tax Mod', width: 200 },
    { key: 'incoterms', label: 'Incoterms', width: 200 },
    { key: 'freight_value_usd', label: 'Freight Value USD', width: 200 },
    { key: 'insurance_value_usd', label: 'Insurance Value USD', width: 200 },
    { key: 'fob_value_botswanan_pula', label: 'fob_value_botswanan_pula', width: 200 },
    { key: 'freight_value_botswanan_pula', label: 'Freight Value botswanan_pula', width: 200 },
    { key: 'insurance_value_botswanan_pula', label: 'insurance_value_botswanan_pula', width: 200 },
    { key: 'item_no', label: 'Item No', width: 200 },
    { key: 'item_value_cif', label: 'Item Value CIF', width: 200 },
    { key: 'item_value_fob', label: 'Item Value Fob', width: 200 },
    { key: 'luxury_tax_mod', label: 'Luxury Tax Mod', width: 200 },
    { key: 'manufacture_year', label: 'Manufacture Year', width: 200 },
    { key: 'menifest_date', label: 'Menifest Date', width: 200 },
    { key: 'mode_of_payment', label: 'Mode of Payment', width: 200 },
    { key: 'no_of_package', label: 'No Of Package', width: 200 },
    { key: 'notify_party_code', label: 'Notify Party Code', width: 100 },
    { key: 'origin_port_code', label: 'Port of Origin Code', width: 200 },  
    { key: 'package_description', label: 'Package Description', width: 200 },
    { key: 'package_type', label: 'Package Type', width: 200 },  
    { key: 'std_quantity', label: 'Std Quantity', width: 200 },
    { key: 'std_unit', label: 'Std Unit', width: 200 },
    { key: 'subitem_no', label: 'Subitem_no', width: 200 },
    { key: 'tax_amount', label: 'Tax Amount', width: 200 },
    { key: 'tax_percentge', label: 'Tax Percentge', width: 200 },
    { key: 'total_freight_value', label: 'Total Freight Value', width: 200 },
    { key: 'total_insurance_value', label: 'Total Insurance Value', width: 200 },
    { key: 'total_invoice_value', label: 'Total Invoice Value', width: 200 },
    { key: 'total_invoice_value_usd', label: 'Total Invoice Value USD', width: 200 },
    { key: 'total_value_bdt', label: 'Total Value_BDT', width: 100 },
    { key: 'total_value_cif_naira', label: 'Total Value CIF NAIRA', width: 200 },
    { key: 'total_value_cif_ugx', label: 'Total Value CIF UGX', width: 200 },
    { key: 'total_value_cif_zwd', label: 'Total Value CIF ZWD', width: 200 },
    { key: 'total_value_etb', label: 'Total Value ETB', width: 200 },
    { key: 'total_value_kes', label: 'Total Value KES', width: 200 },
    { key: 'total_value_lsl', label: 'Total Value LSL', width: 200 },
    { key: 'total_value_naira', label: 'Total Value NAIRA', width: 200 },
    { key: 'total_value_uah', label: 'Total Value UAH', width: 200 },
    { key: 'transport_doc_date', label: 'Transport doc Date', width: 200 },
    { key: 'transport_doc_no', label: 'Transport doc No', width: 200 },
    { key: 'unit_price_bdt', label: 'Unit Price_BDT', width: 100 },
    { key: 'unit_price_kes', label: 'Unit Price_KES', width: 100 },
    { key: 'value_added_tax_amount', label: 'Value Added Tax Amount', width: 200 },
    { key: 'value_added_tax_mod', label: 'Value Added Tax Mod', width: 200 },
    { key: 'variety', label: 'Variety', width: 200 },
    { key: 'vat_preference', label: 'VAT Preference', width: 200 },
    { key: 'importer', label: 'Indian Company Name', width: 200 },
    //{ key: 'address1', label: 'ADDRESS1', width: 200 },
    //{ key: 'address2', label: 'ADDRESS2', width: 200 },
    //{ key: 'city', label: 'CITY', width: 200 },
    //{ key: 'pin', label: 'PIN', width: 200 },
    { key: 'prod_desc', label: 'PROD_DESC', width: 200 },
    { key: 'ind_port', label: 'IND_PORT', width: 200 },
    { key: 'for_count', label: 'FOR_COUNT', width: 200 },
    { key: 'for_port', label: 'FOR_PORT', width: 200 },
    { key: 'value', label: 'VALUE', width: 50 },
    { key: 'value_usd', label: 'VALUE(US$)', width: 50 },
    { key: 'qty', label: 'QTY', width: 200 },   
    { key: 'val_duty_inr', label: 'VAL_DUTY_INR', width: 200 },
    { key: 'val_duty_usd', label: 'VAL_DUTY_USD', width: 200 },
    { key: 'applicable_duty', label: 'Applicable DUTY', width: 200 },
    { key: 'unit_rate_inr', label: 'UNIT RATE(IND RS)', width: 200 },
    { key: 'ship_mode', label: 'SHIP_MODE', width: 200 },
    { key: 'unit_rate_usd', label: 'UNIT RATE(USD)', width: 200 },
    { key: 'beno', label: 'BENO', width: 200 },
    { key: 'cha_no', label: 'CHA_NO', width: 200 },
    { key: 'cha_name', label: 'CHA_NAME', width: 200 },
    { key: 'actual_duty', label: 'Actual DUTY', width: 200 },
    { key: 'total_duty_on_entire_be', label: 'Total Duty On Entire BE', width: 200 },
    { key: 'typ', label: 'Typ', width: 200 },
    { key: 'ag', label: 'AG', width: 200 },
    { key: 'inv_no', label: 'Inv No', width: 200 },
    { key: 'unitprice_fc', label: 'UnitPrice FC', width: 200 },  
    { key: 'foreign_exporter', label: 'Foreign Exporter', width: 200 },
    //{ key: 'foreign_address', label: 'Foreign Address', width: 200 },
    { key: 'date', label: 'Date', width: 130 },
    { key: 'cush', label: 'CUSH', width: 200 },
    { key: 'location', label: 'Location', width: 200 },
    { key: 'inv_date', label: 'Inv_Date', width: 200 },
    { key: 'inv_srl_no', label: 'Inv_Srl_No', width: 200 },
    { key: 'inv_value', label: 'Inv_Value', width: 200 },
    { key: 'forcntry_code', label: 'ForCntry_Code', width: 200 },
    { key: 'forport_code', label: 'FORPORT_Code', width: 200 },
    { key: 'shipmentportcode', label: 'Shipment Port Code', width: 200 },
    { key: 'shipmentport', label: 'Shipment Port', width: 200 },
    { key: 'bcd_notn', label: 'BCD Notn', width: 200 },
    { key: 'bcd_rate', label: 'BCD Rate', width: 200 },
    { key: 'bcd_amt', label: 'BCD Amt', width: 200 },
    { key: 'cvd_notn', label: 'CVD Notn', width: 200 },
    { key: 'cvd_rate', label: 'CVD Rate', width: 200 },
    { key: 'cvd_amt', label: 'CVD Amt', width: 200 },
    { key: 'igst_amt', label: 'IGST Amt', width: 200 },
    { key: 'gst_cess_amt', label: 'GST Cess Amt', width: 200 },
    { key: 'BE_Date', label: 'BE_Date', width: 150 },
    { key: 'BE_NO', label: 'BE_NO', width: 100 },
    
    
];


export const columnListImportDownloadForeign = [
    { key: 'id', label: 'Sl', width: 100 },
	{ key: 'be_date', label: 'Date', width: 200 },
    { key: 'hs_code', label: 'HSCODE', width: 100 },
    { key: 'product_description', label: 'Product Description', width: 200 },
    { key: 'importer_name', label: 'Importer Name', width: 200 },
	{ key: 'importer_address', label: 'Importer Address', width: 200 },
    { key: 'exporter_name', label: 'Exporter Name', width: 200 },
	{ key: 'exporter_address', label: 'Exporter Address', width: 200 },
    { key: 'notify_party_name', label: 'Notify Party Name', width: 200 },
	{ key: 'notify_party_address', label: 'Notify Party Address', width: 200 },	
	{ key: 'net_weight', label: 'Net Weight', width: 100 },
	{ key: 'net_weight_unit', label: 'Net Weight Unit', width: 100 },
	{ key: 'quantity', label: 'Quantity', width: 100 },
    { key: 'unit', label: 'UNIT', width: 200 },
    { key: 'total_value_usd', label: 'Total Value USD', width: 200 },
	{ key: 'unit_price_usd', label: 'Unit Price USD', width: 200 }, 
    { key: 'total_value', label: 'Total Value', width: 100 },
	{ key: 'currency', label: 'Currency', width: 200 },
    { key: 'unit_price', label: 'Unit Price', width: 100 },
    { key: 'origin_country', label: 'Country of Origin', width: 200 },
    { key: 'origin_port', label: 'Origin Port', width: 200 },
	{ key: 'destination_port', label: 'Destination Port', width: 200 },
	{ key: 'place_of_receipt', label: 'Place of Receipt', width: 200 },
    { key: 'bl_number', label: 'BL Number', width: 200 },
    { key: 'container', label: 'Container', width: 200 },
    { key: 'menifest_no', label: 'Menifest No.', width: 200 },
    { key: 'mode_of_transport', label: 'Mode of Transport', width: 200 },
	{ key: 'ship_name', label: 'Ship Name', width: 100 },
    { key: 'transport_company', label: 'Transport Company', width: 200 },
    { key: 'total_value_cif_usd', label: 'Total Value CIF USD', width: 200 },
    { key: 'cif_unit_price', label: 'CIF Unit Price', width: 200 },
	{ key: 'month', label: 'Month', width: 200 },
    { key: 'year', label: 'Year', width: 100 },
    { key: 'hs_code2', label: 'HD Code2', width: 200 },
    { key: 'hs_code4', label: 'HS Code4', width: 200 },
    { key: 'hs_description', label: 'HS Description', width: 200 },
    { key: 'gross_weight', label: 'Gross Weight', width: 200 },
    { key: 'gross_weight_unit', label: 'Gross Weight Unit', width: 200 }, 
    { key: 'assed_unit_value', label: 'Assed Unit Value', width: 200 },
    { key: 'assessed_import_value_pkr', label: 'Assessed Import Value PKR', width: 200 },
    { key: 'assessed_value', label: 'Assessed Value', width: 200 },
	{ key: 'assessed_value_currency', label: 'Assessed Value Currency', width: 200 },
    { key: 'attributes', label: 'Attributes', width: 200 },
    { key: 'be_no', label: 'BE VO.', width: 100 },
    { key: 'bl_date', label: 'BL Date', width: 200 },
    { key: 'brand', label: 'Brand', width: 200 },
    { key: 'commercial_deposit', label: 'Commercial Deposit', width: 200 },
    { key: 'commercial_quantity', label: 'Commercial Quantity', width: 200 },
    { key: 'commercial_quantity_unit', label: 'Commercial Quantity Unit', width: 200 },
    { key: 'condition', label: 'Condition', width: 200 },
    { key: 'conocimiento_emb', label: 'Conocimiento Emb', width: 200 },
    { key: 'custom_clearing_agent', label: 'Custom Clearing Agent', width: 200 },
    { key: 'custom_clearing_agent_address', label: 'Custom Clearing Agent Address', width: 200 },
    { key: 'custom_clearing_agent_code', label: 'Custom Clearing Agent Code', width: 200 },
    { key: 'custom_clearing_office', label: 'Custom Clearing Office', width: 200 },
    { key: 'declarant', label: 'Declarant', width: 100 },
    { key: 'declarant_code', label: 'Declarant Code', width: 100 },
    { key: 'declaration_type', label: 'Declaration Type', width: 200 },
    { key: 'delivery_term_place', label: 'Delivery Term Place', width: 200 },
    { key: 'destination_country', label: 'Destination Country', width: 150 },
    { key: 'destination_port_code', label: 'Destination Port Code', width: 100 },
    { key: 'duty', label: 'Duty', width: 200 },  
    { key: 'duty_mod', label: 'Duty Mod', width: 200 },
    { key: 'economic_zone', label: 'Economic Zone', width: 200 },
    { key: 'exchange_rate', label: 'Exchange Rate', width: 200 },
    { key: 'export_country', label: 'Export Country', width: 200 },
    { key: 'exporter_phone_email', label: 'Exporter Phone Email', width: 200 },
    { key: 'hs_code6', label: 'HS Code6', width: 200 },
    { key: 'iec', label: 'IEC', width: 100 },
    { key: 'import_purpose', label: 'Import Purpose', width: 200 },
    { key: 'import_purpose_group', label: 'Import Purpose Group', width: 200 },
    { key: 'importer_economic_key', label: 'Importer Economic Key', width: 200 },
    { key: 'importer_email', label: 'Importer Email', width: 100 },
    { key: 'importer_phone', label: 'Importer Phone', width: 100 },
    { key: 'income_tax_amount', label: 'Income Tax Amount', width: 100 },
    { key: 'income_tax_mod', label: 'Income Tax Mod', width: 200 },
    { key: 'incoterms', label: 'Incoterms', width: 200 },
    { key: 'freight_value_usd', label: 'Freight Value USD', width: 200 },
    { key: 'insurance_value_usd', label: 'Insurance Value USD', width: 200 },
    { key: 'cif_value_botswanan_pula', label: 'cif_value_botswanan_pula', width: 200 },
    { key: 'fob_value_botswanan_pula', label: 'fob_value_botswanan_pula', width: 200 },
    { key: 'freight_value_botswanan_pula', label: 'Freight Value botswanan_pula', width: 200 },
    { key: 'insurance_value_botswanan_pula', label: 'insurance_value_botswanan_pula', width: 200 },
    { key: 'item_no', label: 'Item No', width: 200 },
    { key: 'item_value_cif', label: 'Item Value CIF', width: 200 },
    { key: 'item_value_fob', label: 'Item Value Fob', width: 200 },
    { key: 'luxury_tax_mod', label: 'Luxury Tax Mod', width: 200 },
    { key: 'manufacture_year', label: 'Manufacture Year', width: 200 },
    { key: 'menifest_date', label: 'Menifest Date', width: 200 },
    { key: 'mode_of_payment', label: 'Mode of Payment', width: 200 },
    { key: 'no_of_package', label: 'No Of Package', width: 200 },
    { key: 'notify_party_code', label: 'Notify Party Code', width: 100 },
    { key: 'origin_port_code', label: 'Port of Origin Code', width: 200 },  
    { key: 'package_description', label: 'Package Description', width: 200 },
    { key: 'package_type', label: 'Package Type', width: 200 },  
    { key: 'std_quantity', label: 'Std Quantity', width: 200 },
    { key: 'std_unit', label: 'Std Unit', width: 200 },
    { key: 'subitem_no', label: 'Subitem_no', width: 200 },
    { key: 'tax_amount', label: 'Tax Amount', width: 200 },
    { key: 'tax_percentge', label: 'Tax Percentge', width: 200 },
    { key: 'total_freight_value', label: 'Total Freight Value', width: 200 },
    { key: 'total_insurance_value', label: 'Total Insurance Value', width: 200 },
    { key: 'total_invoice_value', label: 'Total Invoice Value', width: 200 },
    { key: 'total_invoice_value_usd', label: 'Total Invoice Value USD', width: 200 },
    { key: 'total_value_bdt', label: 'Total Value_BDT', width: 100 },
    { key: 'total_value_cif', label: 'Total Value CIF', width: 200 },
    { key: 'total_value_cif_naira', label: 'Total Value CIF NAIRA', width: 200 },
    { key: 'total_value_cif_ugx', label: 'Total Value CIF UGX', width: 200 },
    { key: 'total_value_cif_zwd', label: 'Total Value CIF ZWD', width: 200 },
    { key: 'total_value_etb', label: 'Total Value ETB', width: 200 },
    { key: 'total_value_kes', label: 'Total Value KES', width: 200 },
    { key: 'total_value_lsl', label: 'Total Value LSL', width: 200 },
    { key: 'total_value_naira', label: 'Total Value NAIRA', width: 200 },
    { key: 'total_value_uah', label: 'Total Value UAH', width: 200 },
    { key: 'transport_doc_date', label: 'Transport doc Date', width: 200 },
    { key: 'transport_doc_no', label: 'Transport doc No', width: 200 },
    { key: 'unit_price_bdt', label: 'Unit Price_BDT', width: 100 },
    { key: 'unit_price_kes', label: 'Unit Price_KES', width: 100 },
    { key: 'value_added_tax_amount', label: 'Value Added Tax Amount', width: 200 },
    { key: 'value_added_tax_mod', label: 'Value Added Tax Mod', width: 200 },
    { key: 'variety', label: 'Variety', width: 200 },
    { key: 'vat_preference', label: 'VAT Preference', width: 200 },
    { key: 'importer', label: 'Indian Company Name', width: 200 },
    { key: 'address1', label: 'ADDRESS1', width: 200 },
    { key: 'address2', label: 'ADDRESS2', width: 200 },
    { key: 'city', label: 'CITY', width: 200 },
    { key: 'pin', label: 'PIN', width: 200 },
    { key: 'prod_desc', label: 'PROD_DESC', width: 200 },
    { key: 'ind_port', label: 'IND_PORT', width: 200 },
    { key: 'for_count', label: 'FOR_COUNT', width: 200 },
    { key: 'for_port', label: 'FOR_PORT', width: 200 },
    { key: 'value', label: 'VALUE', width: 50 },
    { key: 'value_usd', label: 'VALUE(US$)', width: 50 },
    { key: 'qty', label: 'QTY', width: 200 },   
    { key: 'val_duty_inr', label: 'VAL_DUTY_INR', width: 200 },
    { key: 'val_duty_usd', label: 'VAL_DUTY_USD', width: 200 },
    { key: 'applicable_duty', label: 'Applicable DUTY', width: 200 },
    { key: 'unit_rate_inr', label: 'UNIT RATE(IND RS)', width: 200 },
    { key: 'ship_mode', label: 'SHIP_MODE', width: 200 },
    { key: 'unit_rate_usd', label: 'UNIT RATE(USD)', width: 200 },
    { key: 'beno', label: 'BENO', width: 200 },
    { key: 'cha_no', label: 'CHA_NO', width: 200 },
    { key: 'cha_name', label: 'CHA_NAME', width: 200 },
    { key: 'actual_duty', label: 'Actual DUTY', width: 200 },
    { key: 'total_duty_on_entire_be', label: 'Total Duty On Entire BE', width: 200 },
    { key: 'typ', label: 'Typ', width: 200 },
    { key: 'ag', label: 'AG', width: 200 },
    { key: 'inv_no', label: 'Inv No', width: 200 },
    { key: 'unitprice_fc', label: 'UnitPrice FC', width: 200 },  
    { key: 'foreign_exporter', label: 'Foreign Exporter', width: 200 },
    { key: 'foreign_address', label: 'Foreign Address', width: 200 },
    { key: 'date', label: 'Date', width: 130 },
    { key: 'cush', label: 'CUSH', width: 200 },
    { key: 'location', label: 'Location', width: 200 },
    { key: 'inv_date', label: 'Inv_Date', width: 200 },
    { key: 'inv_srl_no', label: 'Inv_Srl_No', width: 200 },
    { key: 'inv_value', label: 'Inv_Value', width: 200 },
    { key: 'forcntry_code', label: 'ForCntry_Code', width: 200 },
    { key: 'forport_code', label: 'FORPORT_Code', width: 200 },
    { key: 'shipmentportcode', label: 'Shipment Port Code', width: 200 },
    { key: 'shipmentport', label: 'Shipment Port', width: 200 },
    { key: 'bcd_notn', label: 'BCD Notn', width: 200 },
    { key: 'bcd_rate', label: 'BCD Rate', width: 200 },
    { key: 'bcd_amt', label: 'BCD Amt', width: 200 },
    { key: 'cvd_notn', label: 'CVD Notn', width: 200 },
    { key: 'cvd_rate', label: 'CVD Rate', width: 200 },
    { key: 'cvd_amt', label: 'CVD Amt', width: 200 },
    { key: 'igst_amt', label: 'IGST Amt', width: 200 },
    { key: 'gst_cess_amt', label: 'GST Cess Amt', width: 200 },
    { key: 'BE_Date', label: 'BE_Date', width: 150 },
    { key: 'BE_NO', label: 'BE_NO', width: 100 },
    
    
];


export const columnListExportForeign = [
    // { key: 'id', label: 'Sl', width: 100 },
	{ key: 'date', label: 'Date', width: 150 },
	{ key: 'hs_code', label: 'HSCODE', width: 150 },
    { key: 'product_description', label: 'Product Description', width: 300 },
	{ key: 'exporter_name', label: 'Exporter Name', width: 200 },
    { key: 'recepient_name', label: 'Recepient Name', width: 200 },
    { key: 'notify_party_name', label: 'Notify Party Name', width: 200 },
    { key: 'destination_country', label: 'Destination Country', width: 150 },
    { key: 'net_weight', label: 'Net Weight', width: 150 },
    { key: 'net_weight_unit', label: 'Net Weight Unit', width: 100 },
    { key: 'quantity', label: 'Quantity', width: 200 },
	{ key: 'unit', label: 'Unit', width: 100 },
    { key: 'total_value_usd', label: 'Total Value USD', width: 200 }, 
    { key: 'unit_price_usd', label: 'Unit Price USD', width: 200 },
    { key: 'total_fob_value', label: 'Total value', width: 200 },
	{ key: 'fob_value_currency', label: 'FOB Value Currency', width: 200 },
    { key: 'currency', label: 'Currency', width: 200 },
    { key: 'unit_price', label: 'Unit Price', width: 100 },
	{ key: 'destination_port', label: 'Destination Port', width: 150 },
    { key: 'origin_port', label: 'Origin Port', width: 200 },
    { key: 'ship_name', label: 'Ship Name', width: 100 },
    { key: 'bl_number', label: 'BL Number', width: 200 },
	//{ key: 'exporter_address', label: 'Exporter Address', width: 200 },
	//{ key: 'recepient_address', label: 'Recepient Address', width: 200 },
	{ key: 'transport_company', label: 'Transport Company', width: 200 },
    { key: 'quantity_of_container', label: 'Quantity of Container', width: 200 },
	{ key: 'voyage_number', label: 'Voyage Number', width: 200 },
    { key: 'type_of_cargo', label: 'Cargo Type', width: 100 },
    { key: 'year', label: 'Year', width: 100 },
    { key: 'month', label: 'Month', width: 200 },
	{ key: 'hs_code2', label: 'HS Code2', width: 150 },
    { key: 'hs_code4', label: 'HS Code4', width: 150 },
    { key: 'hs_description', label: 'HS Description', width: 200 },
    { key: 'commercial_quantity', label: 'Commercial Quantity', width: 200 },
    { key: 'commercial_quantity_unit', label: 'Commercial Quantity Unit', width: 200 },
    { key: 'gross_weight', label: 'Gross Weight', width: 200 },
    { key: 'gross_weight_unit', label: 'Gross Weight Unit', width: 200 },
    { key: 'attributes', label: 'Attributes', width: 200 },
    { key: 'brand', label: 'Brand', width: 200 },
    { key: 'category', label: 'Category', width: 200 },
    { key: 'cif_value_naira', label: 'cif_value_naira', width: 200 },
    { key: 'cif_value_ugx', label: 'cif_value_ugx', width: 200 },
    { key: 'clearance_fee', label: 'Clearance Fee', width: 200 },
    { key: 'clearing_agent', label: 'Clearing Agent', width: 200 },
    { key: 'condition', label: 'Condition', width: 200 },
    { key: 'conocimiento_emb', label: 'Conocimiento Emb', width: 200 },
    { key: 'container', label: 'Container', width: 200 },
    { key: 'customs_clearance_office', label: 'Customs Clearance Office', width: 200 },
    { key: 'declarant', label: 'Declarant', width: 100 },
    { key: 'declarant_code', label: 'Declarant Code', width: 100 },
    { key: 'declaration_type', label: 'Declaration Type', width: 200 },
    { key: 'destination_port_code', label: 'Destination Port Code', width: 100 },
    { key: 'economic_zone', label: 'Economic Zone', width: 200 },
    { key: 'exchange_rate', label: 'Exchange Rate', width: 200 },
    { key: 'export_country', label: 'Export Country', width: 200 },
    { key: 'export_purpose', label: 'Export Purpose', width: 200 },
    { key: 'export_purpose_group', label: 'Export Purpose Group', width: 200 },
    { key: 'exporter_economic_key', label: 'Exporter Economic Key', width: 200 },
    //{ key: 'exporter_tel', label: 'Exporter Phone', width: 200 },
    { key: 'fob_value_naira', label: 'fob_value_naira', width: 200 },
    { key: 'hs_code6', label: 'HS Code6', width: 150 },
    { key: 'iec', label: 'IEC', width: 200 },
    { key: 'incoterm', label: 'Incoterm', width: 200 },
    { key: 'freight_value_usd', label: 'Freight Value USD', width: 200 },
    { key: 'insurance_value_usd', label: 'Insurance Value USD', width: 200 },
    { key: 'freight_value', label: 'Freight Value', width: 200 },
    { key: 'insurance_value', label: 'Insurance Value', width: 200 },
    { key: 'cif_value_botswanan_pula', label: 'cif_value_botswanan_pula', width: 200 },
    { key: 'fob_value_botswanan_pula', label: 'fob_value_botswanan_pula', width: 200 },
    { key: 'freight_value_botswanan_pula', label: 'Freight Value botswanan_pula', width: 200 },
    { key: 'insurance_value_botswanan_pula', label: 'insurance_value_botswanan_pula', width: 200 },
    { key: 'item_no', label: 'Item_no', width: 200 },
    { key: 'item_value_cif', label: 'Item Value CIF', width: 200 },
    { key: 'item_value_fob', label: 'Item Value Fob', width: 200 },
    { key: 'licence_code', label: 'Licence Code', width: 200 },
    { key: 'mode_of_transport', label: 'Mode of Transport', width: 200 },
    { key: 'no_of_package', label: 'No Of Package', width: 200 },
    { key: 'notify_party_code', label: 'Notify Party Code', width: 100 },
    { key: 'origin_country', label: 'Country of Origin', width: 200 },
    { key: 'origin_port_code', label: 'Port of Origin Code', width: 200 },
    { key: 'package_description', label: 'Package Description', width: 200 },
    { key: 'package_type', label: 'Package Type', width: 200 },
    { key: 'payment_mode', label: 'Place of Discharge', width: 200 },
    { key: 'purpose_consumption', label: 'Purpose of Consumption', width: 200 },
	{ key: 'foreign_importer_name', label: 'Foreign Importer Name', width: 200 },
    { key: 'sb_no', label: 'SB_NO', width: 100 },
    { key: 'std_quantity', label: 'Std Quantity', width: 200 },
    { key: 'std_unit', label: 'Std Unit', width: 200 },
    { key: 'subitem_no', label: 'Subitem_no', width: 200 },
    { key: 'tax_amount', label: 'Tax Amount', width: 200 },
    { key: 'total_cif_value', label: 'Total cif value', width: 200 },
    { key: 'total_fob_value_pkr', label: 'Total fob value PKR', width: 200 },
    { key: 'total_fob_value_usd', label: 'Total fob value USD', width: 200 },
    { key: 'total_invoice_value', label: 'Total Invoice Value', width: 200 },
    { key: 'total_invoice_value_cif_usd', label: 'Total Invoice Value CIF USD', width: 200 },
    { key: 'total_value_etb', label: 'Total Value ETB', width: 200 },
    { key: 'transport_doc_date', label: 'Transport doc Date', width: 200 },
    { key: 'transport_doc_no', label: 'Transport doc No', width: 200 },
    { key: 'variety', label: 'Variety', width: 200 },
    { key: 'sb_date', label: 'SB_Date', width: 150 },  
    { key: 'product', label: 'Product', width: 200 },
    { key: 'qty', label: 'QTY', width: 100 },
    { key: 'unit_rate_in_foreign_currency', label: 'Unit Rate in Foreign Currency', width: 180 },
    { key: 'unit_rate_currency', label: 'Unit Rate Currency', width: 100 },
    { key: 'total_sb_value_in_inr_in_lacs', label: 'Total SB Value in INR in Lacs', width: 200 },
    { key: 'value_in_fc', label: 'Value IN FC', width: 200 },
    { key: 'port_of_destination', label: 'Port of Destination', width: 200 },
    { key: 'city_of_destination', label: 'City of Destination', width: 200 },
    { key: 'port_of_origin', label: 'Port of Origin', width: 200 },
    { key: 'indian_exportar_name', label: 'Indian Exporter Name', width: 200 },
    { key: 'exporter_add1', label: 'Exporter Add1', width: 200 },
    { key: 'exporter_add2', label: 'Exporter Add2', width: 200 },
    { key: 'exporter_city', label: 'Exporter City', width: 200 },
    { key: 'for_add1', label: 'FOR_Add1', width: 200 },
    { key: 'for_add2', label: 'FOR_Add2', width: 200 },
    { key: 'for_add3', label: 'FOR_Add3', width: 200 },
    { key: 'for_add4', label: 'FOR_Add4', width: 200 },
    { key: 'importer_country', label: 'Importer Country', width: 200 },   
    { key: 'hs2', label: 'HS2', width: 200 },
    { key: 'hs4', label: 'HS4', width: 200 },
    { key: 'cur_que', label: 'Cur_que', width: 200 },
    { key: 'invoice_no', label: 'Invoice_no', width: 200 },
    { key: 'invoice_srl_no', label: 'Invoice Srl No', width: 200 },
    { key: 'challan_no', label: 'Challan No', width: 200 },
    { key: 'draw_back', label: 'DRAW BACK', width: 200 },
    { key: 'raw_port', label: 'RAW_PORT', width: 200 },
    { key: 'cush', label: 'CUSH', width: 200 },
    { key: 'invoice_date', label: 'Invoice_Date', width: 200 },
    { key: 'cha_no', label: 'CHA_NO', width: 200 },
    { key: 'cha_name', label: 'CHA_NAME', width: 200 },
    { key: 'for_port_code', label: 'For_Port_Code', width: 200 },
    { key: 'leo_date', label: 'LEO_Date', width: 200 },
    { key: 'country_code', label: 'CountryCode', width: 200 },
    { key: 'drawback_excise', label: 'Drawback_Excise', width: 200 },
    { key: 'drawback_customs', label: 'Drawback_Customs', width: 200 },
    { key: 'total_invoice_value_usd', label: 'Total Invoice Value USD', width: 200 },
    //{ key: 'exporter_phone_email', label: 'Exporter Phone Email', width: 200 },  
    { key: 'total_value_bdt', label: 'Total Value BDT', width: 200 },
    { key: 'total_value_uah', label: 'Total Value UAH', width: 200 },   
    { key: 'total_value_kes', label: 'Total Value KES', width: 200 },
    { key: 'total_value_lsl', label: 'Total Value LSL', width: 200 },
    { key: 'total_value_naira', label: 'Total Value NAIRA', width: 200 },   
    { key: 'unit_price_bdt', label: 'Unit Price_BDT', width: 100 },
    { key: 'unit_price_kes', label: 'Unit Price_KES', width: 100 },
    { key: 'duty_mod', label: 'Duty Mod', width: 200 },
    { key: 'duty', label: 'Duty', width: 200 },   
    { key: 'bl_date', label: 'BL Date', width: 200 }, 
    { key: 'menifest_no', label: 'Menifest No.', width: 200 },
    { key: 'menifest_date', label: 'Menifest Date', width: 200 },
    { key: 'incoterms', label: 'Incoterms', width: 200 },
    { key: 'vat_preference', label: 'VAT Preference', width: 200 },
    { key: 'total_freight_value', label: 'Total Freight Value', width: 200 },
    { key: 'total_insurance_value', label: 'Total Insurance Value', width: 200 },
    { key: 'total_value_cif_naira', label: 'Total Value CIF NAIRA', width: 200 },
    { key: 'total_value_cif_ugx', label: 'Total Value CIF UGX', width: 200 },
    { key: 'total_value_cif_usd', label: 'Total Value CIF USD', width: 200 },
    { key: 'total_value_cif_zwd', label: 'Total Value CIF ZWD', width: 200 },
    { key: 'total_value_cif', label: 'Total Value CIF', width: 200 },
    { key: 'cif_unit_price', label: 'CIF Unit Price', width: 200 },
    { key: 'assessed_value', label: 'Assessed Value', width: 200 },
    { key: 'assed_unit_value', label: 'Assed Unit Value', width: 200 },
    { key: 'assessed_import_value_pkr', label: 'Assessed Import Value PKR', width: 200 },
    { key: 'custom_clearing_agent_code', label: 'Custom Clearing Agent Code', width: 200 },
    { key: 'custom_clearing_agent', label: 'Custom Clearing Agent', width: 200 },
    //{ key: 'custom_clearing_agent_address', label: 'Custom Clearing Agent Address', width: 200 },
    { key: 'custom_clearing_office', label: 'Custom Clearing Office', width: 200 },
    { key: 'import_purpose', label: 'Import Purpose', width: 200 },
    { key: 'import_purpose_group', label: 'Import Purpose Group', width: 200 },
    { key: 'mode_of_payment', label: 'Mode of Payment', width: 200 },
    { key: 'tax_percentge', label: 'Tax Percentge', width: 200 },
    { key: 'importer_economic_key', label: 'Importer Economic Key', width: 200 },
    { key: 'commercial_deposit', label: 'Commercial Deposit', width: 200 },
    { key: 'value_added_tax_mod', label: 'Value Added Tax Mod', width: 200 },
    { key: 'value_added_tax_amount', label: 'Value Added Tax Amount', width: 200 },
    { key: 'income_tax_mod', label: 'Income Tax Mod', width: 200 },
    { key: 'income_tax_amount', label: 'Income Tax Amount', width: 200 },
    { key: 'luxury_tax_mod', label: 'Luxury Tax Mod', width: 200 },
    { key: 'manufacture_year', label: 'Manufacture Year', width: 200 },
	


];


export const columnListExportDownloadForeign = [
    // { key: 'id', label: 'Sl', width: 100 },
	{ key: 'date', label: 'Date', width: 150 },
	{ key: 'hs_code', label: 'HSCODE', width: 150 },
    { key: 'product_description', label: 'Product Description', width: 200 },
	{ key: 'exporter_name', label: 'Exporter Name', width: 200 },
	{ key: 'exporter_address', label: 'Exporter Address', width: 200 },
	{ key: 'recepient_name', label: 'Recepient Name', width: 200 },
	{ key: 'recepient_address', label: 'Recepient Address', width: 200 },
	{ key: 'notify_party_name', label: 'Notify Party Name', width: 200 },
    { key: 'net_weight', label: 'Net Weight', width: 150 },
    { key: 'net_weight_unit', label: 'Net Weight Unit', width: 100 },
    { key: 'quantity', label: 'Quantity', width: 200 },
	{ key: 'unit', label: 'Unit', width: 80 },
    { key: 'total_value_usd', label: 'Total Value USD', width: 200 },
    { key: 'unit_price_usd', label: 'Unit Price USD', width: 200 },
    { key: 'total_fob_value', label: 'Total Value', width: 200 },
	{ key: 'fob_value_currency', label: 'FOB Value Currency', width: 200 },
    { key: 'currency', label: 'Currency', width: 200 },
    { key: 'unit_price', label: 'Unit Price', width: 100 },
    { key: 'destination_country', label: 'Destination Country', width: 150 },
	{ key: 'destination_port', label: 'Destination Port', width: 150 },
    { key: 'origin_port', label: 'Origin Port', width: 200 },
    { key: 'ship_name', label: 'Ship Name', width: 100 },
    { key: 'bl_number', label: 'BL Number', width: 200 },
	{ key: 'transport_company', label: 'Transport Company', width: 200 },
    { key: 'quantity_of_container', label: 'Quantity of Container', width: 200 },
	{ key: 'voyage_number', label: 'Voyage Number', width: 200 },
    { key: 'type_of_cargo', label: 'Cargo Type', width: 100 },
    { key: 'year', label: 'Year', width: 100 },
    { key: 'month', label: 'Month', width: 200 },
	{ key: 'hs_code2', label: 'HS Code2', width: 150 },
    { key: 'hs_code4', label: 'HS Code4', width: 150 },
    { key: 'hs_description', label: 'HS Description', width: 200 },
    { key: 'commercial_quantity', label: 'Commercial Quantity', width: 200 },
    { key: 'commercial_quantity_unit', label: 'Commercial Quantity Unit', width: 200 },
    { key: 'gross_weight', label: 'Gross Weight', width: 200 },
    { key: 'gross_weight_unit', label: 'Gross Weight Unit', width: 200 },
    { key: 'attributes', label: 'Attributes', width: 200 },
    { key: 'brand', label: 'Brand', width: 200 },
    { key: 'category', label: 'Category', width: 200 },
    { key: 'cif_value_naira', label: 'cif_value_naira', width: 200 },
    { key: 'cif_value_ugx', label: 'cif_value_ugx', width: 200 },
    { key: 'clearance_fee', label: 'Clearance Fee', width: 200 },
    { key: 'clearing_agent', label: 'Clearing Agent', width: 200 },
    { key: 'condition', label: 'Condition', width: 200 },
    { key: 'conocimiento_emb', label: 'Conocimiento Emb', width: 200 },
    { key: 'container', label: 'Container', width: 200 },
    { key: 'customs_clearance_office', label: 'Customs Clearance Office', width: 200 },
    { key: 'declarant', label: 'Declarant', width: 100 },
    { key: 'declarant_code', label: 'Declarant Code', width: 100 },
    { key: 'declaration_type', label: 'Declaration Type', width: 200 },
    { key: 'destination_port_code', label: 'Destination Port Code', width: 100 },
    { key: 'economic_zone', label: 'Economic Zone', width: 200 },
    { key: 'exchange_rate', label: 'Exchange Rate', width: 200 },
    { key: 'export_country', label: 'Export Country', width: 200 },
    { key: 'export_purpose', label: 'Export Purpose', width: 200 },
    { key: 'export_purpose_group', label: 'Export Purpose Group', width: 200 },
    { key: 'fob_value_naira', label: 'fob_value_naira', width: 200 },
    { key: 'hs_code6', label: 'HS Code6', width: 150 },
    { key: 'iec', label: 'IEC', width: 200 },
    { key: 'incoterm', label: 'Incoterm', width: 200 },
    { key: 'freight_value_usd', label: 'Freight Value USD', width: 200 },
    { key: 'insurance_value_usd', label: 'Insurance Value USD', width: 200 },
    { key: 'freight_value', label: 'Freight Value', width: 200 },
    { key: 'insurance_value', label: 'Insurance Value', width: 200 },
    { key: 'cif_value_botswanan_pula', label: 'cif_value_botswanan_pula', width: 200 },
    { key: 'fob_value_botswanan_pula', label: 'fob_value_botswanan_pula', width: 200 },
    { key: 'freight_value_botswanan_pula', label: 'Freight Value botswanan_pula', width: 200 },
    { key: 'insurance_value_botswanan_pula', label: 'insurance_value_botswanan_pula', width: 200 },
    { key: 'item_no', label: 'Item_no', width: 200 },
    { key: 'item_value_cif', label: 'Item Value CIF', width: 200 },
    { key: 'item_value_fob', label: 'Item Value Fob', width: 200 },
    { key: 'licence_code', label: 'Licence Code', width: 200 },
    { key: 'mode_of_transport', label: 'Mode of Transport', width: 200 },
    { key: 'no_of_package', label: 'No Of Package', width: 200 },
    { key: 'notify_party_code', label: 'Notify Party Code', width: 100 },
    { key: 'origin_country', label: 'Country of Origin', width: 200 },
    { key: 'origin_port_code', label: 'Port of Origin Code', width: 200 },
    { key: 'package_description', label: 'Package Description', width: 200 },
    { key: 'package_type', label: 'Package Type', width: 200 },
    { key: 'payment_mode', label: 'Place of Discharge', width: 200 },
    { key: 'purpose_consumption', label: 'Purpose of Consumption', width: 200 },
    { key: 'sb_no', label: 'SB_NO', width: 100 },
    { key: 'std_quantity', label: 'Std Quantity', width: 200 },
    { key: 'std_unit', label: 'Std Unit', width: 200 },
    { key: 'subitem_no', label: 'Subitem_no', width: 200 },
    { key: 'tax_amount', label: 'Tax Amount', width: 200 },
    { key: 'total_cif_value', label: 'Total cif value', width: 200 },
    { key: 'total_fob_value_pkr', label: 'Total fob value PKR', width: 200 },
    { key: 'total_fob_value_usd', label: 'Total fob value USD', width: 200 },
    { key: 'total_invoice_value', label: 'Total Invoice Value', width: 200 },
    { key: 'total_invoice_value_cif_usd', label: 'Total Invoice Value CIF USD', width: 200 },
    { key: 'total_value_etb', label: 'Total Value ETB', width: 200 },
    { key: 'transport_doc_date', label: 'Transport doc Date', width: 200 },
    { key: 'transport_doc_no', label: 'Transport doc No', width: 200 },
    { key: 'variety', label: 'Variety', width: 200 },
    { key: 'sb_date', label: 'SB_Date', width: 150 },  
    { key: 'product', label: 'Product', width: 200 },
    { key: 'qty', label: 'QTY', width: 100 },
    { key: 'unit_rate_in_foreign_currency', label: 'Unit Rate in Foreign Currency', width: 180 },
    { key: 'unit_rate_currency', label: 'Unit Rate Currency', width: 100 },
    { key: 'total_sb_value_in_inr_in_lacs', label: 'Total SB Value in INR in Lacs', width: 200 },
    { key: 'value_in_fc', label: 'Value IN FC', width: 200 },
    { key: 'port_of_destination', label: 'Port of Destination', width: 200 },
    { key: 'city_of_destination', label: 'City of Destination', width: 200 },
    { key: 'port_of_origin', label: 'Port of Origin', width: 200 },
    { key: 'indian_exportar_name', label: 'Indian Exporter Name', width: 200 },
    { key: 'exporter_add1', label: 'Exporter Add1', width: 200 },
    { key: 'exporter_add2', label: 'Exporter Add2', width: 200 },
    { key: 'exporter_city', label: 'Exporter City', width: 200 },  
    { key: 'foreign_importer_name', label: 'Foreign Importer Name', width: 200 },
    { key: 'for_add1', label: 'FOR_Add1', width: 200 },
    { key: 'for_add2', label: 'FOR_Add2', width: 200 },
    { key: 'for_add3', label: 'FOR_Add3', width: 200 },
    { key: 'for_add4', label: 'FOR_Add4', width: 200 },	
	{ key: 'importer_country', label: 'Importer Country', width: 200 },
    { key: 'hs2', label: 'HS2', width: 200 },
    { key: 'hs4', label: 'HS4', width: 200 },
    { key: 'cur_que', label: 'Cur_que', width: 200 },
    { key: 'invoice_no', label: 'Invoice_no', width: 200 },
    { key: 'invoice_srl_no', label: 'Invoice Srl No', width: 200 },
    { key: 'challan_no', label: 'Challan No', width: 200 },
    { key: 'draw_back', label: 'DRAW BACK', width: 200 },
    { key: 'raw_port', label: 'RAW_PORT', width: 200 },
    { key: 'cush', label: 'CUSH', width: 200 },
    { key: 'invoice_date', label: 'Invoice_Date', width: 200 },
    { key: 'cha_no', label: 'CHA_NO', width: 200 },
    { key: 'cha_name', label: 'CHA_NAME', width: 200 },
    { key: 'for_port_code', label: 'For_Port_Code', width: 200 },
    { key: 'leo_date', label: 'LEO_Date', width: 200 },
    { key: 'country_code', label: 'CountryCode', width: 200 },
    { key: 'drawback_excise', label: 'Drawback_Excise', width: 200 },
    { key: 'drawback_customs', label: 'Drawback_Customs', width: 200 },
    { key: 'total_invoice_value_usd', label: 'Total Invoice Value USD', width: 200 },
    //{ key: 'exporter_phone_email', label: 'Exporter Phone Email', width: 200 },  
    { key: 'total_value_bdt', label: 'Total Value BDT', width: 200 },
    { key: 'total_value_uah', label: 'Total Value UAH', width: 200 },   
    { key: 'total_value_kes', label: 'Total Value KES', width: 200 },
    { key: 'total_value_lsl', label: 'Total Value LSL', width: 200 },
    { key: 'total_value_naira', label: 'Total Value NAIRA', width: 200 },    
    { key: 'unit_price_bdt', label: 'Unit Price_BDT', width: 100 },
    { key: 'unit_price_kes', label: 'Unit Price_KES', width: 100 },
    { key: 'duty_mod', label: 'Duty Mod', width: 200 },
    { key: 'duty', label: 'Duty', width: 200 },   
    { key: 'bl_date', label: 'BL Date', width: 200 }, 
    { key: 'menifest_no', label: 'Menifest No.', width: 200 },
    { key: 'menifest_date', label: 'Menifest Date', width: 200 },
    { key: 'incoterms', label: 'Incoterms', width: 200 },
    { key: 'vat_preference', label: 'VAT Preference', width: 200 },
    { key: 'total_freight_value', label: 'Total Freight Value', width: 200 },
    { key: 'total_insurance_value', label: 'Total Insurance Value', width: 200 },
    { key: 'total_value_cif_naira', label: 'Total Value CIF NAIRA', width: 200 },
    { key: 'total_value_cif_ugx', label: 'Total Value CIF UGX', width: 200 },
    { key: 'total_value_cif_usd', label: 'Total Value CIF USD', width: 200 },
    { key: 'total_value_cif_zwd', label: 'Total Value CIF ZWD', width: 200 },
    { key: 'total_value_cif', label: 'Total Value CIF', width: 200 },
    { key: 'cif_unit_price', label: 'CIF Unit Price', width: 200 },
    { key: 'assessed_value', label: 'Assessed Value', width: 200 },
    { key: 'assed_unit_value', label: 'Assed Unit Value', width: 200 },
    { key: 'assessed_import_value_pkr', label: 'Assessed Import Value PKR', width: 200 },
    { key: 'custom_clearing_agent_code', label: 'Custom Clearing Agent Code', width: 200 },
    { key: 'custom_clearing_agent', label: 'Custom Clearing Agent', width: 200 },
    { key: 'custom_clearing_agent_address', label: 'Custom Clearing Agent Address', width: 200 },
    { key: 'custom_clearing_office', label: 'Custom Clearing Office', width: 200 },
    { key: 'import_purpose', label: 'Import Purpose', width: 200 },
    { key: 'import_purpose_group', label: 'Import Purpose Group', width: 200 },
    { key: 'mode_of_payment', label: 'Mode of Payment', width: 200 },
    { key: 'tax_percentge', label: 'Tax Percentge', width: 200 },
    { key: 'importer_economic_key', label: 'Importer Economic Key', width: 200 },
    { key: 'commercial_deposit', label: 'Commercial Deposit', width: 200 },
    { key: 'value_added_tax_mod', label: 'Value Added Tax Mod', width: 200 },
    { key: 'value_added_tax_amount', label: 'Value Added Tax Amount', width: 200 },
    { key: 'income_tax_mod', label: 'Income Tax Mod', width: 200 },
    { key: 'income_tax_amount', label: 'Income Tax Amount', width: 200 },
    { key: 'luxury_tax_mod', label: 'Luxury Tax Mod', width: 200 },
    { key: 'manufacture_year', label: 'Manufacture Year', width: 200 },
	


];






